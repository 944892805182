import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "../../styles/widjets.pc.css";
import "./nameProject.pc.css";
import "./nameProject.pc.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { TabView, TabPanel } from 'primereact/tabview';
import {
    IProjectCreateReq,
    IProjectDeleteReq,
    IProjectEditReq,
    IProjectGeneralInfo, IProjectUnarchiveReq
} from "../../../models/ProjectModels";
import { Context } from "../../../index";
import { Link, useNavigate, useParams } from "react-router-dom";
import CopyTextButton from "../../buttons/CopyTextButton/CopyTextButton";
import BtnEdit from '../../buttons/btnEdit.component/BtnEdit';
import { AppContext } from '../../../App';
import InputSaveOnEdit from "../InputSaveOnWidjet/InputSaveOnWidjet"
import BtnMore from '../../buttons/btnMore.component/BtnMore';
import AddEditProject from '../../Shared/AddEditProject/AddEditProject';
import BtnAdd from '../../buttons/btnAdd.component/BtnAdd';
import useOutsideAlerter from '../../../customHooks/useOutsideAlert';
import ConfirmationWindow from '../../Shared/ConfirmationWindow/ConfirmationWindow';
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import OpenBlock from "../../../assets/openBlock.svg";
import EditPointsBlue from "../../../assets/moreGray.svg";
import EditPointsGray from "../../../assets/editPontsGray.svg";
import {IHasPermission} from "../../../models/IChekRole";
import {IRestrictAccessBoards} from "../../../models/IRestrictAccess";
import Loader from "../../Shared/Loader/Loader";

interface IProjectNameProps {
    data: IProjectGeneralInfo | undefined;
    loadNameProjectData: () => void;
    userAccess: IHasPermission[];
}

export const NameProjectContext = createContext<any>(null);

const NameProject: React.FC<IProjectNameProps> = ({ data, loadNameProjectData, userAccess }) => {
    const { store } = useContext(Context);
    const { getData, showToast, archivedPage } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [dataForEdit, setDataForEdit] = useState(['Отредактировать проект','Заархивировать проект','Разархивировать проект', 'Удалить проект'])
    const [openInputProject, setOpenInputProject] = useState(false);
    const [openInputBoard, setOpenInputBoard] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0)
    const [valueProj, setValueProj] = useState('');
    const [valueBoard, setValueBoard] = useState('');
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [headerText, setHeaderText] = useState('');
    const [bodyText, setBodyText] = useState('');

    const [deleteFunction, setDeleteFunction] = useState(false);
    const [archiveFunction, setArchiveFunction] = useState(false);

    // Ограничение прав
    const [viewSubProject, setViewSubProject] = useState(false);
    const [viewBoard, setViewBoard] = useState(false);
    const [editProject, setEditProject] = useState(false);
    const [unarchiveProject, setUnarchiveProject] = useState(false);

    const [addBoard, setAddBoard] = useState(false);
    const [addSubProject, setAddSubProject] = useState(false);
    const [delProject, setDelProject] = useState(false);

    //Доступ к борде
    const [allBoardProject, setAllBoardProject] = useState<IRestrictAccessBoards[]>();

    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setOpenInputProject(false);
                setOpenInputBoard(false);
            }
        };
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ProjectAction") { //Описание
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditProject(true);
                    }
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelProject(true);
                    }
                })
            }
            if (xx.functionCode == "ArchiveAction") { //архив
                xx.permissions.forEach((yy) => {
                    if ((yy.permissionCode == "edit" && yy.isGranted == true)  && (data?.parentProjectId ?? undefined) ===  undefined) {
                        setUnarchiveProject(true);
                    }
                })
            }
            if (xx.functionCode == "SubProjectAction") { //Подпроект
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewSubProject(true);
                    }
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddSubProject(true);
                    }
                })
            }
            if (xx.functionCode == "BoardAction") { //Канбан
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddBoard(true);
                    }
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewBoard(true);
                    }
                })
            }
        })

        // if (store.user.email == "admin@admin.adm") {
        //     setViewSubProject(true);
        //     setViewBoard(true);
        //     setEditProject(true);
        //     setAddBoard(true);
        //     setAddSubProject(true);
        //     setDelProject(true);
        // }

        document.addEventListener('keydown', keyDownHandler);
        // Clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [userAccess]);

    const copyProjectNumber = async () => {
        const text = document.getElementById('general-info-widget-project-number')?.innerText;
        if (!text) { return }
        await navigator.clipboard.writeText(text);
    }

    function onAddClick() {
        if (activeIndex === 2) {
            if (addBoard) {
                setOpenInputBoard(true);
            } else {
                showToast("У вас нет прав для создания доски!");
            }
        } else if (activeIndex === 1) {
            if (addSubProject) {
                setOpenInputProject(true);
            } else {
                showToast("У вас нет прав для создания проекта!");
            }
        }
    }

    function onEditClick(e: any) {
        setOpen(false);
        if (e.target.innerText === 'Заархивировать проект') {
            if (editProject) {
                setHeaderText('Заархивировать проект?')
                setBodyText('Вы уверены, что хотите заархивировать этот проект?')
                setArchiveFunction(true)
                setOpenConfirmWindow(true);
            } else {
                showToast("У вас нет прав для редактирования проекта!");
            }
        } else  if (e.target.innerText === 'Разархивировать проект') {
            if (unarchiveProject) {
                setHeaderText('Разархивировать проект?')
                setBodyText('Вы уверены, что хотите разархивировать этот проект?')
                setArchiveFunction(true)
                setOpenConfirmWindow(true);
            } else {
                showToast("У вас нет прав для разархивирования проекта!");
            }
        } else if (e.target.innerText === 'Отредактировать проект') {
            if (editProject) {
                setActiveIndex(0);
                setOpenEdit(true);
            } else {
                showToast("У вас нет прав для редактирования проекта!");
            }
        } else if (e.target.innerText === 'Удалить проект') {
            if (delProject) {
                setHeaderText('Удалить проект?')
                setBodyText('Вы уверены, что хотите удалить этот проект?')
                setDeleteFunction(true)
                setOpenConfirmWindow(true);
            } else {
                showToast("У вас нет прав для удаления проекта!");
            }
        }
    }

    function tabChange(e: any) {
        console.log('tabChange', e.originalEvent.target.innerHTML);
        if (e.originalEvent.target.innerHTML === 'Подпроекты') {
            if (viewSubProject) {
                setActiveIndex(e.index)
            } else {
                showToast("У вас нет прав на прсомотр подпроектов!");
            }
        } else if (e.originalEvent.target.innerHTML === 'Канбан доски') {
            if (viewBoard) {
                setActiveIndex(e.index)
            } else {
                showToast("У вас нет прав на просмотр Канбан досок!");
            }
        } else if (e.originalEvent.target.innerHTML === 'Описание'){
            setActiveIndex(e.index);
        }
    }

    function saveBoard(e: any) {
        setShowLoader(true);
        e.preventDefault();
        let nameBoard = valueBoard;
        id && (async () => {
            try {
                await store.addDashBoard(nameBoard, Number(id), 1);
                loadNameProjectData();
                await getData();
                setValueBoard('');
                setOpenInputBoard(false)
                getBoardNameProject();
                showToast("Канбан доска создана");
                setShowLoader(false);
            } catch (e) {
                showToast(`Произошла ошибка! ${e}`);
                setShowLoader(false);
            }
        })()
    }


    const saveProject = async () => {
        setShowLoader(true);
        if (id) {
            const body :IProjectCreateReq = {
                name: valueProj,
                parentProjectId:Number(id),
                description:"",
                startDate:new Date,
                endDate:new Date,
                photoId: null,
                abbreviation: valueProj.length >= 3 ?  valueProj.slice(0, 3) : data!.abbreviation
            }

            try {
                await store.createProject(body);
                await loadNameProjectData();
                await getData();
                setValueProj('');
                setOpenInputProject(false)
                showToast("Подпроект создан");
                setShowLoader(false);
            } catch(e) {
                showToast(`Произошла ошибка! ${e}`);
                setShowLoader(false);
            }
        }
    }

    const onDelete = async () => {
        let body: IProjectDeleteReq = {
            projectId: Number(id)
        };
        await store.deleteProject(body);
        await getData();
        showToast("Проект удален");
    }

    const onArchive = async () => {
        await store.archiveProject(Number(id));
        await getData();
        showToast("Проект заархивирован");
    }

    const onUnarchiveProjects = async () => {
        let projectId:number[] = [Number(id)]
        let body: IProjectUnarchiveReq = {
            projectIds: projectId
        };
        await store.unarchiveProjects(body);
        await getData();
        showToast("Проект разархивирован");
    }

    const onConfirm = async () => {
        if (deleteFunction){
            await onDelete();
        } else if(archiveFunction && data && data.isArchived){
            await onUnarchiveProjects();
        } else if(archiveFunction && data && !data.isArchived){
            await onArchive();
        }
        setOpenConfirmWindow(false);
        navigate('/projects');
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
        setArchiveFunction(false);
        setDeleteFunction(false);
    }

    const openConfWindow = () => {
        setOpenConfirmWindow(true);
    }

    const handleEditProject = async () => {
        loadNameProjectData();
    }

    // useEffect(() => {
    //     if (!data){
    //         navigate('/projects')
    //     }
    // }, [data]);
    // const getData = async () => {
    const getBoardNameProject = () => {
        (async () => {
            try {
                if (isNaN(store.currentProjectId)) {
                    console.log("Не найден id проекта!");
                    return;
                }

                const boardData = await store.restrictAccess(store.currentProjectId);

                if (boardData == undefined) {
                    console.log("Нет доступа к доске!");
                    return;
                }

                const personsRolesIds: number[] | undefined = boardData?.person.filter(xx => xx.email == store.user.email)?.map(yy => yy.personRoleId);

                if (personsRolesIds == undefined) {
                    console.log("Не найдены пользователи!");
                    return;
                }

                const chekBoard = boardData.boards.filter(xx => {
                    return !xx.personRoleIds?.some(personRoleId => personsRolesIds.includes(personRoleId));
                });


                setAllBoardProject(chekBoard);

                // if (chekBoard.map(xx => xx.id).includes(Number(boardId))) {
            } catch (error) {
                console.log("Пользователь не имеет доступа на доску")
            }
        })();
    }

    useEffect(() => {
        getBoardNameProject();
    }, [userAccess,data]);

    const reroute =()=>{
        navigate('/projects')
    }

    return (
        <NameProjectContext.Provider value={{getBoardNameProject}}>
            {showLoader && (
                <Loader/>
            )}
            <div className={openOnFullWindow ? 'widget_wrapper--full__window' : 'widget_wrapper'}>
                <div className={openOnFullWindow ? 'widget_wrapper-full__window--block' : ''}>
                    {openConfirmWindow && <ConfirmationWindow headerText={headerText}
                                                              bodyText={bodyText}
                                                              confirmText='Нет' cancelText='Да'
                                                              onConfirm={onCancel} onCancel={onConfirm} />}
                    {openEdit &&
                        <AddEditProject
                            setOpen={setOpenEdit}
                            projectInfo={data ? {name: data.projectName, description: data.description, startDate: data.startDate, endDate:data.endDate, photoId: data.photoId, abbreviation: data.abbreviation } : undefined}
                            onAction={handleEditProject}
                            editSubproject={data?.parentProjectId !== null}/>}
                    <div className='widgets_header'>
                        {/*{openOnFullWindow ?*/}
                        {/*    <div onClick={() => setOpenOnFullWindow(false)} className='widgets_header--open__block'>*/}
                        {/*        <img src={CloseBlockWindow}/>*/}
                        {/*    </div>*/}
                        {/*    :*/}
                        {/*    <div onClick={() => setOpenOnFullWindow(true)} className='widgets_header--open__block'>*/}
                        {/*        <img src={OpenBlock}/>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className="widjets_header_left">
                            <h2 className='widget-card-header-style' onLoad={data ? reroute : undefined}>
                                {data?.projectName}
                            </h2>
                        </div>
                        <div className="widjets_header_rigth">
                            {activeIndex === 0 ?
                                <div className="widjets_header_rigth--modal">
                                    <div className="widjets_header_rigth--modal__edit">
                                        <button onClick={() => (editProject || delProject || unarchiveProject ? setOpenModalEdit(!openModalEdit) : showToast("У вас нет прав для редактирования проекта!"))}>
                                            <img className="widjets_header_rigth--modal__edit--img" src={editProject || delProject || unarchiveProject  ? EditPointsBlue : EditPointsGray}/>
                                        </button>
                                    </div>
                                    {openModalEdit ?
                                        <div>
                                            <div className="widjets_header_rigth--modal__window" onMouseLeave={() => setOpenModalEdit(!openModalEdit)}>
                                                {dataForEdit.map((xx) => (
                                                    // {if (xx === 'Заархивировать проект')}
                                                    <button onClick={(e: any) => (onEditClick(e))}
                                                            style={xx.includes("далить") && data?.isArchived === false ? {color: "rgb(186, 78, 65)" } :
                                                                xx.includes("далить")  && data?.isArchived === true ? {display:'none'} :
                                                                xx.includes("Отредактировать")  && data?.isArchived === true ? {display:'none'} :
                                                                xx.includes("Заархивировать проект") && data?.isArchived === true ? {display:'none'} :
                                                                xx.includes("Заархивировать проект") && (data?.parentProjectId ?? undefined) !==  undefined? {display:'none'} :
                                                                xx.includes("Разархивировать проект") && data?.isArchived === false  ? {display:'none'} :
                                                                xx.includes("Разархивировать проект") && (data?.parentProjectId ?? undefined) !==  undefined ? {display:'none'} :
                                                                {}}>
                                                        {xx}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    : null}
                                </div>
                                // <BtnMore onClickFunc={onEditClick} data={dataForEdit} typeForKey='nameProj' open={open} setOpen={setOpen} />
                            :
                                <BtnAdd type={''} onClickFunc={() => (onAddClick())} permission={activeIndex == 2 ? addBoard : addSubProject} />
                            }
                        </div>
                    </div>
                    <div className='nameProj-sub-header'>
                        <p id='general-info-widget-project-number' style={{ marginLeft: "10px" }} className='nameProj_p_11_sub_header_widgets'>{data?.number}</p>
                        <CopyTextButton textDivId='general-info-widget-project-number' />
                    </div>
                    <div className='nameProj_body'>
                        <div>
                            <div>
                                <TabView activeIndex={activeIndex} onTabChange={(e) => (tabChange(e))}>
                                    <TabPanel header="Описание">
                                        <p className="m-0" style={{ display: "flex", flexDirection: "column", height: "100px", overflowY: "auto", wordBreak: "break-word" }}>
                                            {data?.description}
                                        </p>
                                    </TabPanel>
                                    {data?.parentProjectId ? <TabPanel header="Подпроекты" disabled></TabPanel> :
                                        <TabPanel header="Подпроекты"  >
                                            <p className="m-0">
                                                <div className='board-subprojects-container'>
                                                    <InputSaveOnEdit
                                                        value={valueProj}
                                                        setValue={setValueProj}
                                                        open={openInputProject}
                                                        setOpen={setOpenInputProject}
                                                        save={saveProject}
                                                        typeInput='text'
                                                    />
                                                    {data?.childProjects?.map((item, index) => (
                                                        <div key={item.id} style={{ display: "flex", flexDirection: "row", padding: "5px" }}>
                                                            <Link to={`${archivedPage ? "/archive" : ""}/project/${item.id}`}>{item.name}</Link>
                                                            <div className='endData' style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}>
                                                                <div style={{ padding: "5px" }}>Доски: {item.boardCount}</div>
                                                                <div style={{ padding: "5px" }}>Задачи: {item.taskCount}</div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            </p>
                                        </TabPanel>
                                    }
                                    <TabPanel header="Канбан доски">
                                        <p className="m-0">
                                            <div className='board-subprojects-container'>
                                                <InputSaveOnEdit
                                                    value={valueBoard}
                                                    setValue={setValueBoard}
                                                    open={openInputBoard}
                                                    setOpen={setOpenInputBoard}
                                                    save={saveBoard}
                                                    typeInput='text'
                                                />
                                                {data?.boards?.map((item, index) => (
                                                    <div key={item.id} style={{ display: "flex", flexDirection: "row", padding: "5px" }}>
                                                        {allBoardProject?.map(xx => xx.id).includes(item.id) ?
                                                            <Link to={`${archivedPage ? "/archive" : ""}/board/${item.id}`}>
                                                                {item.name}
                                                            </Link>
                                                            :
                                                            <div onClick={() => {showToast("У вас нет доступа к этой доске!")}}>
                                                                {item.name}
                                                            </div>
                                                        }
                                                        <div className='endData' style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}>
                                                            <div style={{ padding: "5px" }}>Задачи: {item.taskCount}</div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </p>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NameProjectContext.Provider>

    )
}

export default observer(NameProject);
