import { FC, useContext, useEffect, useState } from "react";
import "./SubTasks.css";
import RightPopup from "../../../Shared/RightPopup/RightPopup";
import SubTaskSelect from "./SubTaskSelect/SubTaskSelect";
import { ISubTask } from "../../../../models/ISubTask";
import PlusIcon from "../../../../assets/taskIconBtn.svg";
import { IProjectMinimal } from "../../../../models/IProjectMinimal";
import { TaskContext } from "../../../../pages/Task/Task";
import { Link } from "react-router-dom";
import TaskAddEditForm from "../../TaskAddEditForm/TaskAddEditForm";
import { IHasPermission } from "../../../../models/IChekRole";

interface ISubTasksProps {
    subtaskList?: ISubTask[];
    allowedProjects?: IProjectMinimal[];
    setSubTasks?: (tasks: ISubTask[]) => void;
    taskId: number;
    userAccess: IHasPermission[];
}

const SubTasks: FC<ISubTasksProps> = ({ subtaskList, taskId, userAccess }) => {
    const { taskInformation } = useContext(TaskContext);

    const [showSelectPopup, setShowSelectPopup] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [listenerMounted, setListenerMounted] = useState(false);

    //права
    const [showAddSubtaskButton, setShowAddSubtaskbuttond] =
        useState<boolean>(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") { //Информация по задаче
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setShowAddSubtaskbuttond(true);
                    }
                })
            }
        })
    }, [userAccess]);

    function onAddSubTaskClick() {
        setShowAddPopup(!showAddPopup);
        if (!listenerMounted) {
            document.body.addEventListener("click", function (event) {
                let clickedElement = event.target;

                if (
                    !(clickedElement as any)?.classList.contains("keep-popup")
                ) {
                    setShowAddPopup(false);
                }
            });
            setListenerMounted(true);
        }
    }

    const onTaskCreate = () => {
        setShowCreatePopup(false);
    };

    function onShowSubtaskSelect() {
        setShowSelectPopup(!showSelectPopup);
    }

    function onShowSubtaskCreate() {
        setShowCreatePopup(true);
    }

    function filterSubtasks(tasks: ISubTask[]): ISubTask[] {
        let res: ISubTask[] = [];

        //Не сама задача, уникальная и парент = сама задача
        for (let task of tasks ?? []) {
            if (task.id !== taskInformation?.id) {
                if (!res.some((xx) => xx.id == task.id)) {
                    if (task.parentId === taskInformation?.id) {
                        res.push(task);
                    }
                }
            }
        }
        return res;
    }

    return (
        <div className="right-menu-subtask-container">
            <div className="flex-row">
                <div className="p_17" style={{ fontWeight: "bold" }}>
                    Подзадачи
                </div>
                <div style={{ marginLeft: "auto" }}>
                    {showAddSubtaskButton ? (
                        <div>
                            <img
                                style={{ cursor: "pointer" }}
                                className="keep-popup"
                                onClick={onAddSubTaskClick}
                                src={PlusIcon}
                                alt="ADD"
                            />
                        </div>
                    ) : null}
                    {showAddPopup ? (
                        <div className="small-add-option-popup keep-popup">
                            <div
                                className="add-option-text keep-popup"
                                onClick={onShowSubtaskCreate}
                            >
                                Создать
                            </div>
                            <div
                                className="add-option-text keep-popup"
                                onClick={onShowSubtaskSelect}
                            >
                                Выбрать
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="right-menu-subtask-item-container">
                {subtaskList ? (
                    filterSubtasks(subtaskList).map((subTask: ISubTask) => {
                        return (
                            <Link
                                to={`/task/${subTask.id}`}
                                className="right-menu-subtask-item"
                            >
                                [{subTask.number}] {subTask.name}
                            </Link>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>

            {showCreatePopup ? (
                <div className="full-screen-overlay">
                    <div
                        className="popup-form-background"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <TaskAddEditForm
                            mode="create"
                            taskId={undefined}
                            closeFunc={() => setShowCreatePopup(false)}
                            onTaskAction={onTaskCreate}
                            parentTaskId={taskId}
                        />
                    </div>
                </div>
            ) : null}

            {showSelectPopup ? (
                <RightPopup
                    onClose={() => {
                        setShowSelectPopup(false);
                    }}
                    name={"Добавление подзадачи"}
                >
                    <SubTaskSelect />
                </RightPopup>
            ) : (
                <></>
            )}
        </div>
    );
};
export default SubTasks;
