import {FC, useContext, useEffect, useMemo, useState} from "react";
import "./TaskAddEditForm.css";
import {
    ITag,
    ITaskFormDisplay,
    IUserMinimal,
} from "../../../models/ITaskFormDisplay";
import ExitCross from "../../../assets/cancelGreey.svg";
import {Context} from "../../../index";
import TaskType from "../../Shared/TaskType";
import {ITypeAndPriority} from "../../../models/ITypeAndPriority";
import TaskPriority from "../../Shared/TaskPriority";
import TasksTagDisplay from "../../Shared/TagSelectorAndDisplay/TasksTagDisplay";
import {Link, useParams} from "react-router-dom";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import Avatar from "../../../assets/avatar.svg";
import PersonSelector from "../../Shared/PersonSelector/PersonSelector";
import {ITeamFilter} from "../../Project/Team/Team";
import {
    IGetTeamDisplay,
    ITeamMemberDisplay,
} from "../../../models/TeamModels";
import {FloatLabel} from "primereact/floatlabel";
import {Calendar} from "primereact/calendar";
import CustomAlert from "../../Shared/CustomAlert/CustomAlert";
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import {AppContext} from "../../../App";
import {Slider} from "primereact/slider";
import {fullNameFormat, initials} from "../../../helpers/Inicials";
import {IDashBoard, IDashBoards} from "../../../models/IDashBoard";
import board from "../../../pages/Board/Board";
import arrowDown from "../../../assets/arrowDown.svg";
import {DashBoardResponse} from "../../../models/response/DashBoardResponse";
import Loader from "../../Shared/Loader/Loader";
import SimpleMDEditor from "react-simplemde-editor";
import {FileIcons} from "../InputChat/FileIcons";
import unknown_svg from "../../../assets/FileIcons/unknown.svg";
import MarkdownPreview from "../../Shared/MarkdownPreview/MarkdownPreview";
import {chatFileUpload, convertMessage, formatEditMessage, generateFileId} from "../../../helpers/chatFucntion"
import basicTab from "../../SystemSettings/BasicTab/BasicTab";
import files from "../../Project/Files/Files";

type ToolbarButton =
    | "bold"
    | "italic"
    | "quote"
    | "unordered-list"
    | "ordered-list"
    | "link"
    | "image"
    | "strikethrough"
    | "code"
    | "table"
    | "redo"
    | "heading"
    | "undo"
    | "heading-bigger"
    | "heading-smaller"
    | "heading-1"
    | "heading-2"
    | "heading-3"
    | "clean-block"
    | "horizontal-rule"
    | "preview"
    | "side-by-side"
    | "fullscreen"
    | "guide";

interface Options {
    showIcons?: ReadonlyArray<ToolbarButton>;
}

interface ITaskAddEditFormProps {
    mode: "create" | "edit";
    taskId: number | undefined;
    closeFunc: () => void;
    columnId?: number;
    onTaskAction?: () => void;
    projectId?: number;
    permission?: boolean;
    parentTaskId?: number; // Идентификатор родительской задачи, если создается подзадача
}

const TASK_NAME_LIMIT = 250;
const TASK_DESC_LIMIT = 10000;
const TIME_VARIANTS = [1, 2, 3, 4, 5, 6, 7, 8];
const RESPONSIBLE_PLACEHOLDER = {
    id: -1,
    name: "Не выбран",
    picture: Avatar,
};

const TaskAddEditForm: FC<ITaskAddEditFormProps> = ({
                                                        mode,
                                                        taskId,
                                                        closeFunc,
                                                        columnId,
                                                        onTaskAction,
                                                        projectId,
                                                        permission,
                                                        parentTaskId
                                                    }) => {
    //Параметры компонента для определения режима
    const widgetMode =
        mode === "edit" ? "Редактирование задачи" : "Создание задачи";
    const taskIdMode = taskId ? taskId : undefined;
    //Параметры компонента для логики
    const [taskName, setTaskName] = useState<string>("");
    const [taskDesc, setTaskDesc] = useState<string>("");
    const [taskData, setTaskData] = useState<ITaskFormDisplay | undefined>();
    const [taskBoard, setTaskBoard] = useState<string>();
    const [typesPriority, setTypesPriority] = useState<
        ITypeAndPriority | undefined
    >();
    const [showResponsibleSelector, setShowResponsibleSelector] =
        useState<boolean>(false);
    const [scrollEnded, setScrollEnded] = useState<boolean>(false);
    const [persons, setPersons] = useState<ITeamMemberDisplay[]>([]);
    const [boards, setBoards] = useState<IDashBoard[]>();
    const [boardId, setBoardId] = useState<number>();
    const [boardName, setBoardName] = useState<string>();
    const [responsible, setResponsible] = useState<IUserMinimal>();
    const [type, setType] = useState<number>();
    const [priority, setPriority] = useState<number>();
    const [tags, setTags] = useState<ITag[]>([]);
    const [percentageCompletion, setPercentageCompletion] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [customHours, setCustomHours] = useState<number | undefined>(
        undefined
    );
    const [customMinutes, setCustomMinutes] = useState<number | undefined>(
        undefined
    );
    const [showGroupSelector, setShowGroupSelector] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [group, setGroup] = useState<any[]>([]);
    const tabs: string[] = [
        "Основное",
        "Сроки задачи",
        "Подписчики",
        "Прогресс",
    ];
    const {store} = useContext(Context);
    const {showToast} = useContext(AppContext);
    const {id} = useParams();
    const [showLoader, setShowLoader] = useState<boolean>(false);

    //Валидация
    const [nameError, setNameError] = useState<string>();
    const [datesError, setDatesError] = useState<string>();
    const [timeSelectVariant, setTimeSelectVariant] = useState<number>();
    const [priorityError, setPriorityError] = useState<string>();
    const [boardError, setBoardError] = useState<string>();
    const [responsibleError, setResponsibleError] = useState<string>();
    const [typeError, setTypeError] = useState<string>();
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [openBoardsSelect, setBoardsSelect] = useState(false);

    // Марк даун
    const [fileMap, setFileMap] = useState<{
        data: { type: string; id: string; url: string }[];
    }>({ data: [] });
    const [editValue, setEditValue] = useState("");
    const [value, setValue] = useState("");
    const [url, setUrl] = useState("");
    const [masFiles, setMasFiles] = useState<{file: any, id: string}[]>([])
    const [fileCreate, setFileCreate] = useState<any[]>([])
    const [numFileToCreate, setNumFileToCreate] = useState<number>(0)


    const validateName = (): boolean => {
        let nameErr = undefined;

        let nameCheck = taskName?.replace(/\s/g, "");
        if (!nameCheck || !nameCheck.length) {
            nameErr = `Заполните имя задачи`;
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    };

    const validateDates = (): boolean => {
        let datesErr = undefined;

        if (startDate && endDate && endDate < startDate) {
            datesErr = "Дата окончания не может быть раньше даты начала";
        }

        setDatesError(datesErr);
        return datesErr !== undefined;
    };

    const validateResponsible = (): boolean => {
        let responsibleErr = undefined;

        if (!responsible) {
            responsibleErr = "Заполните отвественного в задаче";
        }

        setResponsibleError(responsibleErr);
        return responsibleErr !== undefined;
    };

    const validateType = (): boolean => {
        let typeErr = undefined;

        if (!type) {
            typeErr = "Выберите тип задачи";
        }

        setTypeError(typeErr);
        return typeErr !== undefined;
    };

    const validatePriority = (): boolean => {
        let priorityErr = undefined;

        if (!priority) {
            priorityErr = "Выберите приоритет задачи";
        }

        setPriorityError(priorityErr);
        return priorityErr !== undefined;
    };

    const validateBoard = (): boolean => {
        let boardErr = undefined;

        if (!columnId && !boardId && !taskData) {
            boardErr = "Выберите доску для создания";
        }

        setBoardError(boardErr);
        return boardErr !== undefined;
    };

    function handleTaskAction() {
        (async () => {
            try {
                setShowLoader(true);
                const nameErr = validateName();
                const datesErr = validateDates();
                const typeErr = validateType();
                const priorityErr = validatePriority();
                const responsibleErr = validateResponsible();
                const boardErr = validateBoard();

                if (nameErr || datesErr || responsibleErr || typeErr || priorityErr || boardErr) {
                    setShowLoader(false);
                    return;
                }

                let createRes: ITaskFormDisplay | number | undefined;
                let createReq = compileRequest();

                // Создание задачи
                if (!taskData) {
                    createRes = await store.createTask(createReq);

                    if (createRes && createRes === 404) {
                        setOpenConfirmWindow(true);
                        return;
                    }

                    // ЭТОТ ФУНКЦИОНАЛ ДЛЯ ДОБАВЛЕНИЯ ФАЙЛОВ В ЗАДАЧУ
                    // if (typeof createRes != "number") {
                    //     let allUrlFile: string[] = [];
                    //     let promCheck: Promise<any>[] = [];
                    //     let id = createRes!.id;
                    //
                    //     fileCreate.forEach(xx => {
                    //         let promise = store.addFileChat(xx, id, 1);
                    //         promCheck.push(promise.then((res) => {allUrlFile.push(String(res));}));
                    //     })
                    //
                    //     // Получаем id файлов
                    //     await Promise.all(promCheck)
                    //     // allUrlFile
                    // }
                } else {
                    createReq.id = taskData.id;
                    await store.editTask(createReq);
                }
                if (onTaskAction) onTaskAction();

                closeFunc();
                store.setDrag(true);
                if (taskData) {
                    showToast("Задача обновлена");
                } else if (createRes && typeof createRes !== "number") {
                    showToast(
                        "Задача создана",
                        <Link to={`/task/${createRes.id}`}>Перейти к задаче</Link>
                    );
                }
                setShowLoader(false);
            } catch (err) {
                showToast(`Произошла ошибка! ${err}`)
            }
        })();
    }

    const taskTypeDisplay = () => {
        return typesPriority?.type?.map((item) => {
            const styleItem = JSON.parse(item.style);
            return (
                <button
                    onClick={() => handleTypeSelection(item.id)}
                    style={{cursor: "pointer"}}
                    key={item.id}
                    className={type != item.id ? "opacity-40" : ""}
                >
                    <TaskType
                        name={item.name}
                        color={styleItem.color}
                        icon={styleItem.icon}
                    />
                </button>
            );
        });
    };

    const taskPriorityDisplay = () => {
        return typesPriority?.priority?.map((item) => {
            const styleItem = JSON.parse(item.style);
            return (
                <button
                    onClick={() => handlePrioritySelection(item.id)}
                    style={{cursor: "pointer"}}
                    key={item.id}
                    className={priority != item.id ? "opacity-40" : ""}
                >
                    <TaskPriority
                        name={item.name}
                        color={styleItem.color}
                        icon={styleItem.icon}
                    />
                </button>
            );
        });
    };

    function onChangeCustomTime(event: any) {
        setTimeSelectVariant(undefined);

        if (event.target.id == "custom-hours-input") {
            setCustomHours(event.target.value.replace(/\D/g, "").slice(0, 4));
        }

        if (event.target.id == "custom-minutes-input") {
            let input = event.target.value.replace(/\D/g, "").slice(0, 2);
            if (Number(input) > 59) {
                setCustomMinutes(59);
            } else {
                setCustomMinutes(input);
            }
        }
    }

    function selectTimeVariant(event: any) {
        setTimeSelectVariant(event.target.innerText);
        setCustomHours(event.target.innerText);
        setCustomMinutes(0);
    }

    function handleGroupSelection(personId: number) {
        let person = persons?.find((xx) => xx.id == personId);
        setGroup([...group, person]);
        console.log(personId, [...group, person])
    }

    function onAddTags(_tags: ITag[]) {
        let newTags = tags?.filter((xx) => xx.id < 0);

        setTags(
            [...newTags, ..._tags].filter(
                (value, index, self) => self.indexOf(value) === index
            )
        );
    }

    function handleResponsibleSelection(personId: number) {
        let person = persons?.find((xx) => xx.id == personId);

        setResponsible(person as any);
        closeResponsibleSelector();
    }

    function handleGroupRemove(personId: number) {
        let groupNoPerson = group?.filter((xx: any) => xx.id != personId);
        setGroup(groupNoPerson);
    }

    const appendPersons = async (skip: number, take: number, filters: ITeamFilter) => {
        const newPageReq: IGetTeamDisplay = {
            skip: skip,
            take: take,
            filters: filters,
        };

        const res = await store.getProjectTeamDisplayData(newPageReq);
        if (res?.team && res?.team?.length > 0) {
            setPersons([...persons, ...res.team]);
        } else {
            setScrollEnded(true);
        }
    };

    async function openGroupSelector() {
        setShowGroupSelector(true);
        await loadPersons(0, 5, {
            projectId: projectId ?? store.currentProjectId,
            name: undefined,
            role: undefined,
        });
    }

    function closeGroupSelector() {
        setShowGroupSelector(false);
    }

    function compileRequest(): any {
        let reqTime: number = 0;
        if (customHours || customMinutes) {
            reqTime = customHours ? reqTime + Number(customHours) * 60 : reqTime;
            reqTime = customMinutes ? reqTime + Number(customMinutes) : reqTime;
        } else if (timeSelectVariant) {
            reqTime = timeSelectVariant * 60;
        }

        let oldTags = tags?.filter((xx) => xx.id > 0);
        let newTags = tags?.filter((xx) => xx.id < 0);

        let req: any = {
            projectId: projectId ?? store.currentProjectId,
            boardId: boardId,
            columnId: columnId!,
            name: taskName.trim(),
            description: editValue!,
            type: type!,
            priorityId: priority!,
            requiredTime: Number(reqTime),
            group: group?.map((x) => x.id),
            responsible: responsible?.id,
            endDate: endDate,
            startDate: startDate,
            percentageCompletion: Number(percentageCompletion),
            statusId: undefined,
            files: fileCreate,
            tags: {
                old: oldTags?.map((xx) => xx.id) ?? undefined,
                new: newTags?.map((xx) => {
                    return {
                        name: xx.name,
                        color: xx.color,
                    };
                }),
            },
            parentTaskId: parentTaskId
        };
        return req;
    }

    const loadPersons = async (
        skip: number,
        take: number,
        filters: ITeamFilter
    ) => {
        const newPageReq: IGetTeamDisplay = {
            skip: skip,
            take: take,
            filters: filters,
        };

        const res = await store.getProjectTeamDisplayData(newPageReq);
        res && res.team
            ? setPersons(structuredClone(res?.team))
            : setPersons([]);
    };

    const [triggerId, setTriggerId] = useState<string>()
    const [triggerDataSave, setTriggerDataSave] = useState<string>()

    useEffect(() => {
        console.log(fileMap)
        setEditValue((prevEditValue) => prevEditValue + ` ${triggerDataSave}`);
        setTaskDesc(value + `${triggerId}`)
        setValue((oldValue) => oldValue + `${triggerId}`);
    }, [triggerId, triggerDataSave]);


    const imageUpload = async (file: any, onSuccess: any, onError: any) => {
        showToast("Загрузка файлов в описание временно не поддерживается!")
        // try {
        //     if (mode == "create") {
        //         const url = `URL-FOR-CHANGE-${numFileToCreate}`
        //         setNumFileToCreate((prevState) => prevState + 1);
        //         console.log(file)
        //         let data = chatFileUpload(url, file, fileMap);
        //         let fileId = data.fileId;
        //         let dataSave = data.dataSave;
        //
        //
        //
        //         setFileCreate(prevState => [...prevState, file])
        //         setFileMap(data.currentFileMap);
        //         setTriggerId(` {${fileId}}`);
        //         setTriggerDataSave(` ${dataSave}`);
        //
        //     } else {
        //         const url = await store.addFileChat(file, Number(id), 1);
        //
        //         if (url?.status != 400) {
        //             store.setUpdateFile(true);
        //
        //             let data = chatFileUpload(url, file, fileMap);
        //             let fileId = data.fileId;
        //             let dataSave = data.dataSave;
        //
        //             setFileMap(data.currentFileMap);
        //             setTriggerId(` {${fileId}}`);
        //             setTriggerDataSave(` ${dataSave}`);
        //
        //
        //             showToast(`Файл ${"{" + fileId + "}"} успешно загружен!`);
        //         } else {
        //             showToast(url?.data?.message);
        //         }
        //     }
        // } catch (err) {
        //     showToast(`Произошла ошибка! ${err}`)
        // }
    };

    function fileIcon(fileType: string | undefined) {
        let fileTypeClean = fileType?.replace(/\./g, "");
        let variableName = `${fileTypeClean}_svg`;
        let pictureSrc = (FileIcons as any)[variableName];
        if (!pictureSrc) {
            //Тут если не нашли картинку нужна женерик картинка файла
            return unknown_svg;
        } else {
            return pictureSrc;
        }
    }

    // Изменение в Markdown
    const onChange = (newValue: string) => {
        const data = convertMessage(newValue, fileMap);
        if (data.arrayChatFiles) {
            setFileMap(data.arrayChatFiles);
        }
        setTaskDesc(data.disassembledText);
        setEditValue(data.disassembledText);
        setValue(newValue);
    };

    // Опции маркдауна
    const newOptions = useMemo(() => {
        return {
            spellChecker: false,
            hideIcons: [
                "link",
                "image",
                "strikethrough",
                "table",
                "heading-bigger",
                "heading-smaller",
                "heading-1",
                "heading-2",
                "heading-3",
                "clean-block",
                "preview",
                "side-by-side",
                "fullscreen",
                "guide",
            ],
            showIcons: [
                "code",
                "bold",
                "italic",
                "quote",
                "unordered-list",
                "ordered-list",
                "code",
                "redo",
                "undo",
                "heading",
                "horizontal-rule",
            ],
            uploadImage: true,
            imageUploadFunction: imageUpload,
            placeholder: "Введите текст...",
        } as Options;
    }, []);

    function handlePersonsFilterUpdate() {

    }

    function removeTag(tag: ITag) {
        setTags([...tags?.filter((xx) => xx.id !== tag.id)]);
    }

    function handleTypeSelection(typeId: number) {
        setType(typeId);
    }

    function handlePrioritySelection(priorityId: number) {
        setPriority(priorityId);
    }

    function closeResponsibleSelector() {
        setShowResponsibleSelector(false);
    }

    function onChangeStartDate(event: any) {
        setStartDate(event.target.value);
    }

    function onChangeEndDate(event: any) {
        setEndDate(event.target.value);
    }

    function openResponsibleSelector() {
        setShowResponsibleSelector(true);
    }

    function clearResponsible() {
        setResponsible(undefined);
    }

    function onChangeName(event: any) {
        setTaskName(event.target.value);
    }

    function onChangeDesc(event: any) {
        setTaskDesc(event.target.value);
    }

    function onChangeCompletionPercentage(event: any) {
        setPercentageCompletion(event.target.value);
    }

    const onConfirm = () => {
        setOpenConfirmWindow(false);
    };

    function changePage(id: number) {
        setTab(id);
    }

    useEffect(() => {
        store.setDrag(false);
        (async () => {
            try {
                // Получение типов и приоритетов
                const typePriorityData = await store.getTypeAndPriority();
                setTypesPriority(typePriorityData);
                console.log("columnId", columnId)

                if (columnId == undefined) {
                    const boardsRaw = await store.getProjectDashBoards(projectId ?? store.currentProjectId)

                    if (boardsRaw) {
                        let dashBoards = boardsRaw as unknown as IDashBoard[]
                        let dataBoards = dashBoards.filter(xx=>xx.isProxyBoard===false);
                        setBoards(dataBoards)
                        setBoardId(dataBoards[0].id)
                        setBoardName(dataBoards[0].name)
                    } else {
                        setBoardId(undefined)
                        setBoardName("Нет досок для добавления")
                    }
                }

                setValue("")
                setTaskDesc("")
                setEditValue("")

                if (window.location.pathname.includes('board')) {
                    setBoardId(Number(window.location.pathname.split("/").pop()))
                }

                // Получение данных по задаче если виджет открыт на редактирование
                if (taskId != undefined) {
                    const res = await store.getTaskForm(taskId);
                    if (!res?.id) {
                        return;
                    }
                    const sd = new Date(res.startDate);
                    const ed = new Date(res.endDate);
                    const requiredTimeInMinutes = res.requiredTime;
                    const hours = Math.floor(requiredTimeInMinutes / 60);
                    const minutes = requiredTimeInMinutes % 60;
                    setTaskData(res); 
                    setTaskName(res.name);
                    // setTaskDesc();
                    setEditValue(res.description ? res.description : "")
                    let newDataText = formatEditMessage(res.description ? res.description : "", fileMap);
                    setTaskDesc(newDataText.disassembledText);
                    setValue(newDataText.disassembledText);
                    if (newDataText.arrayChatFiles) {
                        setFileMap(newDataText.arrayChatFiles);
                    }
                    setBoardId(Number(window.location.pathname.split("/").pop()))


                    setPriority(res.priority.id);
                    setType(res.type.id);
                    setTags(res.tags);
                    setStartDate(sd);
                    setEndDate(ed);
                    setResponsible(res?.responsible);
                    setCustomHours(hours);
                    setCustomMinutes(minutes);
                    setTimeSelectVariant(hours);
                    setGroup(structuredClone(res.group));
                    setPercentageCompletion((res as any).percentageCompletion); //TODO обновить интерфейс
                }
                await loadPersons(0, 5, {
                    projectId: projectId ?? store.currentProjectId,
                    // projectId: 16,
                    name: undefined,
                    role: undefined,
                });
            } catch (error) {
                console.log(error);
            }
        })();
        console.log(id);
    }, []);

    function boardIdSave(id: number, name: string) {
        setBoardId(id)
        setBoardName(name)
        setBoardsSelect(false)
    }

    return (
        <div className="task__create--edit__form">
            {openConfirmWindow && (
                <CustomAlert
                    headerText="Уведомление об ошибке"
                    bodyText="Вы не можете создать задачу, так как у проекта отсутствую доски или колонки у доски"
                    confirmText="Ок"
                    onConfirm={onConfirm}
                />
            )}
            {showLoader && (
                <Loader/>
            )}
            <div className="task__create--edit__form--header">
                <div className="task__create--edit__form--head">
                    {widgetMode}
                </div>
                <div className="task__create--edit__form--button">
                    <button
                        onClick={() => {
                            store.setDrag(true);
                            closeFunc();
                        }}
                    >
                        <img src={ExitCross}/>
                    </button>
                </div>
            </div>
            <div className="task__create--edit--form__tab--menu">
                {tabs.map((tabName, i) => (
                    <button
                        className={`task__create--edit--form__tab--menu--button
                            ${
                            (tab == i
                                ? "task__create--edit--form__tab--menu--button__selected"
                                : "") +
                            " " +
                            ((nameError ||
                                responsibleError ||
                                typeError ||
                                priorityError) &&
                            tabName == "Основное"
                                ? " task__create--edit__form--tab__panel__err"
                                : "")
                        }`}
                        onClick={() => changePage(i)}
                    >
                        {tabName}
                    </button>
                ))}
            </div>
            {tab == 0 ? (
                <div className="task__create--edit__form--tab__panel--block">
                    <div className="task__create--edit__form--tab__panel--name__task">
                        Название задачи*
                    </div>
                    <div>
                        <textarea
                            className={`task__create--edit__form--tab__panel--textarea ${
                                nameError ? "textarea_with_error" : ""
                            }`}
                            value={
                                taskName?.length! > TASK_NAME_LIMIT
                                    ? taskName?.slice(0, TASK_NAME_LIMIT)
                                    : taskName
                            }
                            placeholder="Введите название задачи"
                            cols={30}
                            rows={2}
                            onChange={onChangeName}
                        ></textarea>
                    </div>
                    <div className="task__create--edit__form--tab__panel--textarea__lenght">
                        <div>
                            {taskName?.length! > TASK_NAME_LIMIT
                                ? taskName?.slice(0, TASK_NAME_LIMIT)?.length
                                : taskName?.length}
                            /{TASK_NAME_LIMIT}
                        </div>
                    </div>
                    <div className="task__create--edit__form--tab__panel--about__task">
                        Описание задачи
                    </div>
                    {/*<MarkdownPreview*/}
                    {/*    content={taskDesc}*/}
                    {/*/>*/}
                    <SimpleMDEditor
                        id="editor"
                        value={taskDesc?.length! > TASK_DESC_LIMIT
                            ? taskDesc?.slice(0, TASK_DESC_LIMIT)
                            : taskDesc}
                        onChange={onChange}
                        options={newOptions}
                    />
                    {/*<div>*/}
                    {/*    <textarea*/}
                    {/*        className="task__create--edit__form--tab__panel--textarea__about"*/}
                    {/*        value={*/}
                    {/*            taskDesc?.length! > TASK_DESC_LIMIT*/}
                    {/*                ? taskDesc?.slice(0, TASK_DESC_LIMIT)*/}
                    {/*                : taskDesc*/}
                    {/*        }*/}
                    {/*        placeholder="Опишите свою задачу"*/}
                    {/*        cols={30}*/}
                    {/*        rows={5}*/}
                    {/*        onChange={onChangeDesc}*/}
                    {/*    ></textarea>*/}
                    {/*</div>*/}
                    <div>
                        {!columnId && !taskData  ?
                            <div>
                                <div className="task__create--edit__form--tab__panel--type__task">
                                    Доска*
                                </div>
                                <div className={`task__create--edit__form--tab__panel--type__block--border conteiner_pointer ${
                                    boardError ? "div_with_error" : ""}`} onClick={() => setBoardsSelect(!openBoardsSelect)}>
                                    <div style={{width: "100%"}}>
                                        {boardName?.length ? boardName : "Выберите доску"}
                                    </div>
                                    <img src={arrowDown} style={openBoardsSelect ? {rotate: '180deg'} : {rotate: '0deg'}}/>
                                </div>
                                {openBoardsSelect ?
                                    <div className="full-screen-overlay__board--width">
                                        <div
                                            style={{opacity: "0.5"}}
                                            className="full-screen-overlay__board"
                                            onClick={() => setBoardsSelect(!openBoardsSelect)}
                                        ></div>
                                        <div
                                            className={'task__create--edit__form--tab__panel--type__block--border overView task__create--edit__form--tab__panel--type__block--border__board'}>
                                            {boards?.map(xx =>
                                                <div className="task__create--edit__form--tab__panel--tab__board"
                                                     id={`${xx.id}`} onClick={() => boardIdSave(xx.id, xx.name)}>
                                                    {xx.name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    : null}
                            </div> : null
                        }
                        <div className="task__create--edit__form--tab__panel--type__task">
                            Тип задачи*
                        </div>
                        <div
                            className={`task__create--edit__form--tab__panel--type__block--border ${
                                typeError ? "div_with_error" : ""
                            }`}
                        >
                            {taskTypeDisplay()}
                        </div>
                    </div>
                    <div>
                        <div className="task__create--edit__form--tab__panel--type__task">
                            Приоритет*
                        </div>
                        <div
                            className={`task__create--edit__form--tab__panel--type__block--border ${
                                priorityError ? "div_with_error" : ""
                            }`}
                        >
                            {taskPriorityDisplay()}
                        </div>
                    </div>
                    <div>
                        <div className="task__create--edit__form--tab__panel--type__task">
                            Теги
                        </div>
                        <div className="task__create--edit__form--tab__panel--type__block--border">
                            <TasksTagDisplay
                                projectId={projectId ?? store.currentProjectId}
                                taskId={taskIdMode}
                                tags={tags}
                                addTagsFunc={onAddTags}
                                removeTag={removeTag}
                                permission={mode === "create" ? true : permission}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="task__create--edit__form--tab__panel--type__task">
                            Исполнитель*
                        </div>
                        <div className={`task__create--edit__form--tab__panel--type__block__response ${responsibleError ? "div_with_error" : ""}`} onClick={openResponsibleSelector}>
                            <div className="task__create--edit__form--tab__panel--block__person">
                                <PersonLineDisplay
                                    name={
                                        responsible
                                            ? responsible.name
                                                ? fullNameFormat(
                                                    responsible,
                                                    "s N M"
                                                )
                                                : initials(
                                                    (responsible as any)
                                                        .responsibleName
                                                )
                                            : RESPONSIBLE_PLACEHOLDER.name
                                    }
                                    photoId={
                                        (responsible as any)
                                            ?.responsiblePhotoId ??
                                        responsible?.photoId
                                    }
                                />
                                {responsible ? (
                                    <div
                                        className="task__create--edit__form--tab__panel--type__block--delete"
                                        onClick={clearResponsible}
                                    >
                                        <img src={ExitCross}/>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {showResponsibleSelector ? (
                            <div style={{position: "relative"}}>
                                <div
                                    style={{opacity: "0.5"}}
                                    className="full-screen-overlay__group"
                                    onClick={closeResponsibleSelector}
                                ></div>
                                <PersonSelector
                                    updateFilters={handlePersonsFilterUpdate}
                                    loadPage={appendPersons}
                                    scrollEnded={false}
                                    members={persons
                                        ?.filter(
                                            (xx) => xx.id != responsible?.id
                                        )
                                        .map((person) => {
                                            return {
                                                id: person.id,
                                                role: person.position,
                                                name: person.responsibleName, // TODO нужно поменять на 3 переменных
                                                surname: "",
                                                middleName: "",
                                                photoId:
                                                person.responsiblePhotoId,
                                            };
                                        })}
                                    selectPerson={handleResponsibleSelection}
                                    projectId={projectId}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            ) : tab == 1 ? (
                <div className="task__create--edit__form--tab__panel--type__task--all">
                    <div className="task__create--edit__form--tab__panel--type__task">
                        Дата выполнения задачи
                    </div>
                    <div
                        className={`calendar--block__flex ${
                            datesError ? "input_with_error" : ""
                        }`}
                    >
                        <div className="calendar--block__flex--inp">
                            <FloatLabel>
                                <Calendar
                                    dateFormat="dd.mm.yy"
                                    onChange={onChangeStartDate}
                                    value={startDate}
                                />
                                <label htmlFor="Дата начала">Дата начала</label>
                            </FloatLabel>
                        </div>
                        <div className="calendar--block__flex--inp">
                            <FloatLabel>
                                <Calendar
                                    dateFormat="dd.mm.yy"
                                    onChange={onChangeEndDate}
                                    value={endDate}
                                />
                                <label htmlFor="Дата начала">
                                    Дата окончания
                                </label>
                            </FloatLabel>
                        </div>
                    </div>
                    {datesError ? (
                        <p
                            className="error_message"
                            style={{marginTop: "5px"}}
                        >
                            {datesError}
                        </p>
                    ) : null}
                    <div className="task__create--edit__form--tab__panel--type__task">
                        Срок выполнения в часах
                    </div>
                    <div className="time-variants-container">
                        {TIME_VARIANTS.map(
                            (
                                item
                                //TODO добавить стиль если item == timeSelectVariant
                            ) => (
                                <button
                                    onClick={selectTimeVariant}
                                    className={
                                        item == timeSelectVariant
                                            ? "time-variants-container__chose"
                                            : "time-variants-container__unchose"
                                    }
                                    key={item}
                                >
                                    {item}
                                </button>
                            )
                        )}
                    </div>
                    <div className="task__create--edit__form--tab__panel--type__task">
                        Свое время
                    </div>
                    <div className="custom-time-container">
                        <div className="custom-time-container--input">
                            <input
                                pattern="\d*"
                                value={customHours}
                                onChange={onChangeCustomTime}
                                type="text"
                                id="custom-hours-input"
                                className="custom-time-input"
                            />
                            <div className="custom-time-container--time__text">
                                часы
                            </div>
                        </div>
                        <div>
                            <div className="custom-time-container--pass">:</div>
                            <div></div>
                        </div>
                        <div>
                            <div className="custom-time-container--input">
                                <input
                                    pattern="\d*"
                                    value={customMinutes}
                                    onChange={onChangeCustomTime}
                                    type="text"
                                    id="custom-minutes-input"
                                    className="custom-time-input"
                                />
                                <div className="custom-time-container--time__text">
                                    минуты
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : tab == 2 ? (
                <div className="task__create--edit__form--tab__panel--type__task--all">
                    <div className="task__create--edit__form--tab__panel--type__task">
                        Подписчики
                    </div>
                    <div className="task__create--edit__form--tab__panel--type__block">
                        <div>
                            <BtnAdd
                                type="gd563grt433"
                                onClickFunc={openGroupSelector}
                                permission={true}
                            />
                        </div>
                        <div style={{paddingLeft: "10px"}}>
                            {group?.length > 0 ? (
                                group.map((user) => (
                                    <div
                                        className="task__create--edit__form--tab__panel--type__block--group"
                                        key={user.id}
                                        title={
                                            user.name ??
                                            initials(user.responsibleName)
                                        }
                                    >
                                        <PersonLineDisplay
                                            name={
                                                user.name
                                                    ? fullNameFormat(
                                                        user,
                                                        "s N M"
                                                    )
                                                    : initials(
                                                        (user as any)
                                                            .responsibleName
                                                    )
                                            }
                                            photoId={
                                                user.responsiblePhotoId ??
                                                user.photoId
                                            }
                                        />
                                        <div
                                            className="task__create--edit__form--tab__panel--type__block--delete"
                                            onClick={() =>
                                                handleGroupRemove(user.id)
                                            }
                                        >
                                            <img src={ExitCross}/>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    {showGroupSelector ? (
                        <div
                            style={{
                                position: "relative",
                                top: "70px",
                                left: "30px",
                            }}
                        >
                            <div
                                style={{opacity: "0.5"}}
                                className="full-screen-overlay__group"
                                onClick={closeGroupSelector}
                            ></div>
                            <PersonSelector
                                updateFilters={handlePersonsFilterUpdate}
                                loadPage={appendPersons}
                                scrollEnded={false}
                                members={persons?.filter(
                                        (xx) => !group.some((yy) => yy.id == xx.id)
                                    )
                                    .map((person) => {
                                        return {
                                            id: person.id,
                                            role: person.position,
                                            name: person.responsibleName, // TODO нужно поменять на 3 переменных
                                            surname: "",
                                            middleName: "",
                                            photoId: person.responsiblePhotoId,
                                        };
                                    })}
                                selectPerson={handleGroupSelection}
                                projectId={projectId}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <div className="task__create--edit__form--tab__panel--type__task--all">
                    <div className="task__create--edit__form--tab__panel--type__task">
                        Процент выполнения задачи
                    </div>
                    <div className="task-completion-percentage-container">
                        <a style={{width: "70px", padding: "7px"}}>
                            {percentageCompletion} %
                        </a>
                        <div style={{width: "100%", verticalAlign: "center"}}>
                            <Slider
                                className="task-completion-percentage-slider"
                                style={{height: "7px", borderRadius: "3px"}}
                                step={1}
                                value={percentageCompletion}
                                onChange={(e) =>
                                    setPercentageCompletion(e.value as any)
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="task__create--edit__form--tab__panel--button">
                <div className="task__create--edit__form--tab__panel--button__close"  id={"TaskAddEditForm_cancel_btn"} onClick={closeFunc}>
                    <button >Отмена</button>
                </div>
                <div className="task__create--edit__form--tab__panel--button__add" id={"TaskAddEditForm_submit_btn"} onClick={() => (handleTaskAction())}>
                    <button >
                        {taskData !== undefined ? "Сохранить" : "Создать"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TaskAddEditForm;
