import React, {createContext, FC, useContext, useEffect, useRef, useState} from "react";
import TaskInformationForm from "../../component/Task/TaskForm/TaskInformationForm";
import "./Task.css";
import TaskRightInfo from "../../component/Task/TaskRightInfo/TaskRightInfo";
import TaskChat from "../../component/Task/TaskChat/TaskChat";
import { AppContext } from "../../App";
import { Context } from "../..";
import { IBreadCrumb } from "../../models/IBreadCrumb";
import { useParams } from "react-router-dom";
import { ITaskGeneralInfo } from "../../models/ITask";
import { IGetAllTaskPerson } from "../../models/IAllTaskPerson";

import { ICheckRole, IHasPermission } from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import {
    IGetTaskHistoryDisplay,
    ITaskHistoryFilter,
} from "../../models/TaskModels";
import { IProjectHistoryDisplay } from "../../models/ProjectModels";
import TaskChecklist from "../../component/Task/TaskChecklist/TaskChecklist";
import TaskHistory from "../../component/Task/TaskHistory/TaskHistory";
import tasks from "../../component/Project/Tasks/Tasks";
import { SEO } from "../../component/Helmet/HelmetSEO";
import Favicon from "../../assets/favicon-128x128.png";
import {ISubTask, ISubTaskView} from "../../models/ISubTask";
import {IProjectMinimal} from "../../models/IProjectMinimal";
import SubTasksGraph from "../../component/Task/TaskRightInfo/SubTasksGraph/SubTasksGraph";

const HISTORY_PAGE_SIZE = 20;

interface TaskContextType {
    taskInformation: ITaskGeneralInfo | undefined;
    loadTaskInfo: () => void;
    getAllTaskPerson: () => void;
    allPerson: IGetAllTaskPerson | undefined;
    scrollComponentDiv: HTMLDivElement | undefined;
    subTasks: ISubTask[];
    loadSubTasks:  () => void;
    allowedProjects?: IProjectMinimal[];
    onSubTaskSelectControlClick?: (task: ISubTask) => void;
}

export const TaskContext = createContext<TaskContextType>({
    taskInformation: undefined,
    loadTaskInfo: () => {},
    getAllTaskPerson: () => {},
    allPerson: undefined, 
    scrollComponentDiv: undefined,
    subTasks: [],
    loadSubTasks: () => {},
    allowedProjects: []
});

const tabs: string[] = [
    "Комментарии",
    "Чек-лист",
    "Связанные задачи",
    "История по задаче",
];

const Task: FC = () => {
    const { setBreadCrumb, findBoard, sideBarProjects, setNavPanelHighlight, archivedPage, pageTitle, archivedProjects } = useContext(AppContext);
    const { store } = useContext(Context);
    const [defaultBoardId, setDefaultBoardId] = useState<number>();
    const { boardId, id } = useParams();
    const [allPerson, setAllPerson] = useState<IGetAllTaskPerson | undefined>(
        undefined
    );
    const [taskInformation, setTaskInformation] = useState<ITaskGeneralInfo>();
    const [historySkip, setHistorySkip] = useState<number>(0);
    const [haveMoreRecords, setHaveMoreRecords] = useState<boolean>(true);
    const [taskHistory, setTaskHistory] = useState<IProjectHistoryDisplay[]>(
        []
    );
    const [subtasksHead, setSubtasksHead] = useState<ISubTaskView>();

    const [taskHistoryFilters, setTaskHistoryFilters] =
        useState<ITaskHistoryFilter>({
            taskId: Number(id),
        });

    //Подзадачи
    const [allowedProjects, setAllowedProjects] = useState<IProjectMinimal[]>([]);
    //Тут лежит все дерево задач доступных и нет для связи как подзадача.
    const [subTasks, setSubTasks] = useState<ISubTask[]>([]);

    async function getAllowedProjects(){
        let projects = await store.getAllowedProjects(Number(id))
        if(projects?.length){
            setAllowedProjects(projects)
        }
    }

    const loadSubTasks = async () => {
        if (store.getBlockSubtasks())
            return;
        let subtasks = await store.getSubTasks(Number(id))
        if(subtasks?.length){
            setSubTasks(subtasks)
        }
        const subTasksResp = await store.getSubTasksTree(Number(id));
        if (subTasksResp) setSubtasksHead(subTasksResp);
    }

    const [tab, setTab] = React.useState<number>(0);
    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const scrollComponentDiv = useRef(null);

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);
    const [editTask, setEditTask] = useState(false);

    useEffect(() => {

        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                });
            }
        });

        // if (store.user.email == "admin@admin.adm") {
        //     setViewHistory(true);
        //     setEditTask(true);
        // }
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            await loadTaskInfo();
            await getAllTaskPerson();

            //Подзадачи
            await getAllowedProjects()
            await loadSubTasks()
        })();
    }, [id]);

    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem()
                const newCheckRole: ICheckRole = {
                    projectId: taskInformation?.projectId,
                    boardId: taskInformation?.board.id,
                    functionCodes: functionClass.getTaskFunction
                };

                const checkThisRole = await store.hasPermission(newCheckRole);
                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error)
            }
        })();
    }, [taskInformation]);

    useEffect(() => {
        (async () => {
            if (viewHistory) {
                await loadTaskHistory(0, HISTORY_PAGE_SIZE, undefined, false);
                setHistorySkip(HISTORY_PAGE_SIZE);
            }
        })();
    }, [viewHistory, id]);

    useEffect(() => {
        if (archivedPage) {
            if (taskInformation && defaultBoardId) {
                let bread: IBreadCrumb[] = [
                    {
                        label: "Архив",
                        url: "/archive",
                    },
                ];
    
                bread = [...bread, ...findBoard(archivedProjects, defaultBoardId, true)];
                bread.push({
                    label: taskInformation.shortDescription,
                    url: "/task/" + taskInformation.id,
                });
    
                setBreadCrumb(bread);
            }
        } else {
            if (taskInformation && defaultBoardId) {
                let bread: IBreadCrumb[] = [
                    {
                        label: "Проекты",
                        url: "/projects",
                    },
                ];
    
                bread = [...bread, ...findBoard(sideBarProjects, defaultBoardId, false)];
                bread.push({
                    label: taskInformation.shortDescription,
                    url: "/task/" + taskInformation.id,
                });
    
                setBreadCrumb(bread);
                setNavPanelHighlight(undefined);
            }
        }
    }, [setBreadCrumb, taskInformation, defaultBoardId, sideBarProjects, archivedPage]);

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem();
                const newCheckRole: ICheckRole = {
                    projectId: Number(id),
                    boardId: undefined,
                    functionCodes: functionClass.getTaskFunction,
                };

                const checkThisRole = await store.hasPermission(newCheckRole);

                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                await loadTaskHistory(
                    0,
                    HISTORY_PAGE_SIZE,
                    taskHistoryFilters,
                    false
                );
                setHistorySkip(HISTORY_PAGE_SIZE);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [taskHistoryFilters]);

    const loadTaskHistory = async (
        skip: number,
        take: number,
        filters: ITaskHistoryFilter | undefined,
        append: boolean
    ): Promise<boolean> => {
        let req: IGetTaskHistoryDisplay = {
            skip: skip,
            take: take,
            filters: filters ?? { taskId: Number(id) },
            sort: {
                date: "desc",
            },
        };

        const res = await store.getTaskHistory(req);
        if (!res) return false;
        if (Array.isArray(res)) {
            if (append) setTaskHistory([...taskHistory, ...res]);
            else setTaskHistory(res);
        }
        return res.length > 0;
    };

    const loadTaskInfo = async () => {
        console.log("TaskId", Number(id))
        let res = await store.getTask({

            taskId: Number(id),
            boardId: boardId ? Number(boardId) : undefined,
        });
        setTaskInformation(res);
        setDefaultBoardId(res?.board.id);
    };

    const getAllTaskPerson = async () => {
        const res = await store.getAllTaskPerson(Number(id));
        setAllPerson(res);
    };

    const onScroll = async (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight) {
            if (tab === 3 && haveMoreRecords) {
                setHistorySkip((prev: number) => prev + HISTORY_PAGE_SIZE);

                setHaveMoreRecords(
                    await loadTaskHistory(
                        historySkip,
                        HISTORY_PAGE_SIZE,
                        taskHistoryFilters,
                        true
                    )
                );
            }
        }
    };

    return (
        <TaskContext.Provider
            value={{
                taskInformation,
                loadTaskInfo,
                getAllTaskPerson,
                allPerson,
                scrollComponentDiv: scrollComponentDiv.current ?? undefined,
                subTasks,
                loadSubTasks,
                allowedProjects
            }}
        >
            <SEO title={taskInformation ? taskInformation.projectAbrevation + "-" +
                (taskInformation.id < 10 ? '00' + taskInformation.id : (taskInformation.id < 100 ? '0' + taskInformation.id : taskInformation.id)) + " · " +
                (taskInformation.shortDescription.length > 70 ? taskInformation.shortDescription.slice(0, 67) + "..." : taskInformation.shortDescription ) + " | Задача · " + pageTitle : pageTitle} />
            <div className="widgets_task_container">
                <div className="widgets_task_container_1" onScroll={onScroll} ref={scrollComponentDiv}>
                    <div className="widgets_task_container_sub">
                        <TaskInformationForm
                            taskNumber={taskInformation ? taskInformation.projectAbrevation + "-" +
                                (taskInformation.id < 10 ? '00' + taskInformation.id : (taskInformation.id < 100 ? '0' + taskInformation.id : taskInformation.id)):''}
                            projectId={taskInformation?.projectId}
                            userAccess={userAccess}
                        />
                    </div>
                    <div className="task_tabs--header">
                        <div className="task__tabs">
                            {tabs.map((tabName, i) => 
                                <>
                                    {
                                        store.getBlockSubtasks() && tabName === tabs[2] ? null : (
                                            <button
                                                className={`system__settings--header__button ${
                                                    tab === i
                                                        ? "system__settings--header__button__selected"
                                                        : ""
                                                }`}
                                                onClick={() => setTab(i)}
                                            >
                                                {tabName}
                                            </button>
                                        )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        {tab === 0 ? (
                            <div>
                                {taskInformation ? (
                                    <TaskChat userAccess={userAccess} chatId={taskInformation.chatId}/>
                                ) : null}
                            </div>
                        ) : tab === 1 ? (
                            <div>
                                <TaskChecklist userAccess={userAccess} />
                            </div>
                        ) : tab === 2 ? (
                            <div
                                className="widget_wrapper"
                            >
                                {id ? (<SubTasksGraph taskId={Number(id)} treeHead={subtasksHead}/>) : null}
                            </div>
                        ) : (
                            <div>
                                <div
                                    id="main-history-widget"
                                    className="widget_wrapper"
                                >
                                    <div className="task-history-table-container">
                                        {viewHistory ? (
                                            <TaskHistory
                                                records={taskHistory}
                                                onChangeFilters={
                                                    setTaskHistoryFilters
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="widgets_task_container_2">
                    <TaskRightInfo setSubTasks={setSubTasks} userAccess={userAccess}/>
                </div>
            </div>
        </TaskContext.Provider>
    );
};

export default Task;
