export const HistoryActions = new Map<string, string>([
  ["create", "Создание"],
  ["edit", "Редактирование"],
  ["delete", "Удаление"],
  ["changePassword", "Смена пароля"],
  ["invite", 'Приглашение']
]);

export const ProjectHistoryActions = new Map<string, string>([
  ["create", "Создание"],
  ["edit", "Редактирование"],
  ["delete", "Удаление"],
]);