import {
    createContext,
    Dispatch,
    FC,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context } from "../../index";
import "../../globalstyles/page.pc.css";
import "../../pages/Main/MainPage.css";
import "../../pages/UserMonitor/UserMonitor.css";
import "../../component/Project/Tasks/TasksWidget.css";

import { AppContext } from "../../App";
import UserMonitorToolbar from "../../component/UserMonitor/ToolBar/UserMonitorToolbar";
import { ITaskMonitorFilters } from "../../models/TaskModels";
import {
    IGetTaskSort,
    IMonitorTaskDisplay,
    IMonitorTasksFilter,
} from "../../models/IUserMonitorTaskFullInfo";
import { ISelectedFastFilters } from "../Board/Board";
import { IFilter, IFilterItem } from "../../models/response/IFilterResponse";
import { IIParametrsFilter } from "../../models/IFilterModel";
import TasksTable from "../../component/UserMonitor/TasksTable/TasksTable";
import TaskDescription from "../../component/UserMonitor/TaskDescription/TaskDescription";
import TasksInfo from "../../component/UserMonitor/TasksInfo/TasksInfo";
import {ICheckRole, IHasPermission} from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";

export interface ISortOption {
    lable: string;
    sort: "asc" | "desc" | undefined;
    code: string;
}

interface IUserMonitorContext {
    fastFilters: ITaskMonitorFilters;
    selectedFastFilters: ISelectedFastFilters;
    changeFastFilters: (filters: ISelectedFastFilters) => void;
    filters: IFilter[];
    setFilters: Dispatch<SetStateAction<IFilter[]>>;
    datesFilter: any;
    setDatesFilter: Dispatch<SetStateAction<any>>;
    checkedFilters: any;
    setCheckedFilters: Dispatch<any>;
    resetFilter: () => void;
}

export const UserMonitorContext = createContext<IUserMonitorContext>({fastFilters: { priority: [], type: [] },
    selectedFastFilters: {priority: undefined, type: undefined, tag: undefined,},
    changeFastFilters: () => {}, filters: [],
    setFilters: () => {}, checkedFilters: {},
    setCheckedFilters: () => {}, datesFilter: undefined,
    setDatesFilter: () => {}, resetFilter: () => {},
});

const UserMonitor: FC = () => {
    const { store } = useContext(Context);
    const { setBreadCrumb, setNavPanelHighlight } = useContext(AppContext);
    //Задачи
    const [tasks, setTasks] = useState<IMonitorTaskDisplay[]>([]);
    const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>(undefined);
    const [favouriteTaskIds, setFavouriteTaskIds] = useState<number[]>([]);
    const TABLE_PAGE_SIZE = 15; // Инкремент задач при пагинации в режиме таблицы
    const [skip, setSkip] = useState(0);
    const [scrollEndedTasks, setScrollEndedTasks] = useState<boolean>(false);
    //Фильтры
    const [taskNameSearch, setTaskNameSearch] = useState<string>("");
    //Быстрые фильтры
    const [fastFilters, setFastFilters] = useState<ITaskMonitorFilters>({priority: [], type: [],});
    const [selectedFastFilters, setSelectedFastFilters] =
        useState<ISelectedFastFilters>({priority: undefined, type: undefined, tag: undefined,});
    const [filters, setFilters] = useState<IFilter[]>([]);
    const [resetFilters, setResetFilters] = useState<IFilter[]>([]);
    const [datesFilter, setDatesFilter] = useState<any>(null);
    const [checkedFilters, setCheckedFilters] = useState<any>({
        priority: undefined, typeTask: undefined,});
    const [forceFilterChange, setForceFilterChange] = useState<Boolean>(false);

    //Сортировки
    const [dataSort, setDataSort] = useState<ISortOption[]>([
        { lable: "НАЗВАНИЕ ЗАДАЧИ", sort: undefined, code: "nameTask" },
        { lable: "ПРОЕКТ", sort: undefined, code: "projectTask" },
        { lable: "СТОЛБЕЦ", sort: undefined, code: "columnTask" },
        { lable: "СТАТУС", sort: undefined, code: "status" },
        { lable: "ПРИОРИТЕТ", sort: undefined, code: "priority" },
        { lable: "ТИП ЗАДАЧИ", sort: undefined, code: "type" },
        { lable: "ДАТА", sort: undefined, code: "date" },
    ]);
    //Тогглы
    const [showOnlyFavourite, setShowOnlyFavourite] = useState<boolean>(store.userMonitorShowFavourite);

    //Эти кастомные сеты нужны чтобы не было бага при включении звездочки.
    function setShowOnlyFavouriteFunc(value: boolean) {
        setSelectedTaskId(undefined);
        setShowOnlyFavourite(value);
        store.setUserMonitorShowFavourite(value);
    }
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    function setShowCompletedFunc(value: boolean) {
        setSelectedTaskId(undefined);
        setShowCompleted(value);
    }

    //Избранное
    //Правый бар
    const [totalStats, setTotalStats] =
        useState<{total: number; ongoing: number; completed: number; overdue: number; } | undefined>(undefined);


    useEffect(() => {
        (async () => {
            setBreadCrumb([{ label: "Монитор пользователя", url: "monitor" }]);
            setNavPanelHighlight("monitor");
            const fastFilters = await store.getMonitorFilters();
            if (fastFilters) {
                setFastFilters(fastFilters);
            }

            const filterParams: IIParametrsFilter = {
                projectId: -1, // Каюсь, грешил, но с таким удовольствием
                date: true,
                responsible: false,
                priority: true,
                typeTask: true,
                status: false,
                role: false,
                fileType: false,
            };
            let data = await store.getDataForFilter(filterParams);
            if (data) {
                setFilters(data);
                setResetFilters(structuredClone(data));
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setSelectedTaskId(undefined);
            loadTasks();
        })();
    }, [
        showOnlyFavourite,
        showCompleted,
        taskNameSearch,
        selectedFastFilters,
        checkedFilters,
        datesFilter,
        dataSort,
    ]);

    const loadTasks = async () => {
        store.getFavouriteTasks().then((res) => {
            if (res?.favourited) {
                setFavouriteTaskIds(res.favourited);
            }
        });
        setScrollEndedTasks(false);

        store
            .getMonitorTasks({
                skip: 0,
                take: TABLE_PAGE_SIZE,
                filters: getCurrentFilters(),
                sort: getCurrentSort(),
            })
            .then((res) => {
                if (res) {
                    setTasks(res.tasks);
                    setTotalStats(res.rightBarStats);
                }
                setSkip(TABLE_PAGE_SIZE);
            });
    };

    const loadPage = async () => {
        store
            .getMonitorTasks({
                skip: skip,
                take: TABLE_PAGE_SIZE,
                filters: getCurrentFilters(),
                sort: getCurrentSort(),
            })
            .then((res) => {
                if (res?.tasks && res?.tasks?.length > 0) {
                    setTasks((prev) => [...prev, ...res.tasks]);
                } else {
                    setScrollEndedTasks(true);
                }
            });
    };

    const getCurrentFilters = (): IMonitorTasksFilter => {
        const priorityFilter = checkedFilters.priority
            ? checkedFilters.priority
            : selectedFastFilters.priority
            ? [selectedFastFilters.priority?.id]
            : undefined;

        const typeFilter = checkedFilters.typeTask
            ? checkedFilters.typeTask
            : selectedFastFilters.type
            ? [selectedFastFilters.type?.id]
            : undefined;

        const dateFilter =
            datesFilter && datesFilter[0]
                ? datesFilter.filter((i: any) => i !== null)
                : undefined;

        const filter: IMonitorTasksFilter = {
            responsible: undefined,
            date: dateFilter,
            status: undefined,
            priority: priorityFilter,
            typeTask: typeFilter,
            name: taskNameSearch,
            favourite: showOnlyFavourite,
            onlyOngoing: !showCompleted,
        };

        return filter;
    };

    const getCurrentSort = (): IGetTaskSort => {
        const sortOption = dataSort.find((s) => s.sort);

        const sort: IGetTaskSort = {
            date: sortOption?.code === "date" ? sortOption.sort : undefined,
            status: sortOption?.code === "status" ? sortOption.sort : undefined,
            type: sortOption?.code === "type" ? sortOption.sort : undefined,
            priority:
                sortOption?.code === "priority" ? sortOption.sort : undefined,
            nameTask:
                sortOption?.code === "nameTask" ? sortOption.sort : undefined,
            project:
                sortOption?.code === "projectTask"
                    ? sortOption.sort
                    : undefined,
        };

        return sort;
    };

    useEffect(() => {
        if (!forceFilterChange) {
            if (
                checkedFilters.priority &&
                checkedFilters.priority.length > 0 &&
                checkedFilters.typeTask &&
                checkedFilters.typeTask.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: undefined,
                    tag: undefined,
                });
            } else if (
                checkedFilters.priority &&
                checkedFilters.priority.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: selectedFastFilters.type,
                    tag: undefined,
                });
            } else if (
                checkedFilters.typeTask &&
                checkedFilters.typeTask.length > 0
            ) {
                setSelectedFastFilters({
                    priority: selectedFastFilters.priority,
                    type: undefined,
                    tag: undefined,
                });
            }
        }
        setForceFilterChange(false);
    }, [checkedFilters]);

    const changeFastFilters = (fastFilters: ISelectedFastFilters) => {
        setSelectedFastFilters(fastFilters);

        const filtersCopy: IFilter[] = structuredClone(filters);

        filtersCopy.forEach((f) => {
            if (f.nameForFilter === "priority") {
                (f.items as IFilterItem[])?.forEach((i) => {
                    i.checked = false;
                    if (i.id == fastFilters.priority?.id) i.checked = true;
                });
            }
            if (f.nameForFilter === "typeTask") {
                (f.items as IFilterItem[])?.forEach((i) => {
                    i.checked = false;
                    if (i.id == fastFilters.type?.id) i.checked = true;
                });
            }
        });
        let checkedFiltersCopy = structuredClone(checkedFilters);

        checkedFiltersCopy.priority = fastFilters.priority
            ? [fastFilters.priority.id]
            : undefined;
        checkedFiltersCopy.typeTask = fastFilters.type
            ? [fastFilters.type.id]
            : undefined;

        setCheckedFilters(checkedFiltersCopy);
        setFilters(filtersCopy);
        setForceFilterChange(true);
    };

    const resetFilter = () => {
        setTaskNameSearch("");
        setDatesFilter([]);
        setCheckedFilters({
            priority: undefined,
            typeTask: undefined,
        });
        setFilters(structuredClone(resetFilters));
        setSelectedFastFilters({
            priority: undefined,
            type: undefined,
            tag: undefined,
        });
    };

    async function updateFavourite(newFavourite: { favourited: number[] }) {
        let tasksUpdated = await store.setFavouriteTasks(newFavourite);

        if (tasksUpdated?.favourited) {
            setFavouriteTaskIds(tasksUpdated.favourited);
        }
    }

    function onSortChange() {
        const dataSortCopy = structuredClone(dataSort);
        dataSortCopy.forEach((s: ISortOption) => {
            if (s.sort)
                s.sort = !s.sort
                    ? "asc"
                    : s.sort === "asc"
                    ? "desc"
                    : undefined;
        });
        setDataSort(dataSortCopy);
        setSelectedTaskId(undefined);
    }

    function addSort(code: string) {
        const dataSortCopy = structuredClone(dataSort);
        dataSortCopy.forEach((s: ISortOption) => {
            s.sort = s.code === code ? "asc" : undefined;
        });
        setDataSort(dataSortCopy);
        setSelectedTaskId(undefined);
    }

    const onScroll = async (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if (1 + scrollTop + offsetHeight >= scrollHeight && !scrollEndedTasks) {
            const pageSize = TABLE_PAGE_SIZE;
            setSkip((prev: number) => prev + pageSize);
            await loadPage();
        }
    };

    return (
        <UserMonitorContext.Provider
            value={{
                selectedFastFilters, changeFastFilters,
                fastFilters, filters,
                setFilters, checkedFilters,
                setCheckedFilters, datesFilter,
                setDatesFilter, resetFilter,
            }}
        >
            <div className="user-monitor-page-main-container">
                <div className="user-monitor-main-widget-area">
                    <div className="user-monitor-top-toolbar card-shadow">
                        <UserMonitorToolbar
                            setShowCompleted={setShowCompletedFunc}
                            setShowOnlyFavourite={setShowOnlyFavouriteFunc}
                            showCompleted={showCompleted}
                            showOnlyFavourite={showOnlyFavourite}
                            taskNameSearch={taskNameSearch}
                            setTaskNameSearch={setTaskNameSearch}
                        />
                    </div>
                    <TasksTable
                        dataSort={dataSort}
                        onSortChange={onSortChange}
                        addSort={addSort}
                        selectedTaskId={selectedTaskId}
                        setSelectedTaskId={setSelectedTaskId}
                        tasks={tasks}
                        favouriteTaskIds={favouriteTaskIds}
                        updateFavourite={updateFavourite}
                        onScroll={onScroll}
                    />
                </div>
                <div className="user-monitor-right-bar-container card-shadow">
                    {selectedTaskId ? (
                        <TaskDescription
                            task={tasks.find((t) => t.id === selectedTaskId)!}
                            setSelectedTaskId={setSelectedTaskId}
                        />
                    ) : (
                        <TasksInfo totalStats={totalStats} />
                    )}
                </div>
            </div>
        </UserMonitorContext.Provider>
    );
};

export default UserMonitor;
