import React, {createContext, ReactNode, useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Authorization from "./pages/Authorization/Authorization";
import Login from "./pages/Login/Login";
import Project from "./pages/Project/Project";
import Board from "./pages/Board/Board";
import Projects from './pages/Projects/Projects';
import Header from './component/Header/Header';
import MenuForm from './component/LeftMenu/MenuForm';
import {Context} from '.';
import UserMonitor from "./pages/UserMonitor/UserMonitor";
import Reference from "./pages/Reference/Reference";
import Arhive from "./pages/Archive/Archive";
import Task from "./pages/Task/Task";
import './component/styles/input.css'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
import Feedback from './pages/Feedback/Feedback';
import ToastNotification from './component/Shared/ToastNotification/ToastNotification';
import SystemSettings from "./pages/SystemSettings/SystemSettings";
import {ISideBarBoard, ISideBarProject, ISideBarSubproject} from './models/LeftMenuModels';
import {IBreadCrumb} from './models/IBreadCrumb';
import {HighlightOptions, INavBarInfo} from './component/LeftMenu/NavigationMenu/NavigationPanel';
import {addLocale, locale} from "primereact/api";
import GuardedRoute from './guards/GuardedRoute';
import LoadingPage from './pages/Loading/LoadingPage';
import {ICheckRole, IHasPermission} from "./models/IChekRole";
import FunctionSystem from "./models/functionCode/functionsSystem";
import LoginToken from './pages/TokenTest/LoginToken';
import NoAcessPages from "./pages/NoAcessPages/NoAcessPages";
import { HeadProvider, Title, Link, Meta } from 'react-head';
import Favicon from "./assets/favicon-128x128.png"
import NoAccessPages from "./pages/NoAcessPages/NoAcessPages";
import { SEO } from './component/Helmet/HelmetSEO';

// Локализация для календаря primereact
addLocale("ru", {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
    today: 'Сегодня',
    clear: 'Очистить'
});

locale("ru");

// Тип уведомления
export type Toast = {
    id: number;
    text: string;
    content: ReactNode;
};

export const AppContext = createContext<any>(null);

const App = () => {
    const MAX_ZOOM = 1;
    const MIN_ZOOM = 0.5;

    const [breadCrumb, setBreadCrumb] = useState<IBreadCrumb[]>([]);
    const {store} = useContext(Context);
    const [sideBarProjects, setSideBarProjects] = useState<ISideBarProject[]>([]);
    const [archivedProjects, setArchivedProjects] = useState<ISideBarProject[]>([]);
    const [navBarInfo, setNavBarInfo] = useState<INavBarInfo>({})
    const [isMainPage, setIsMainPage] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentZoom, setCurrentZoom] = useState<number>(1);
    const [boardScrollPosition, setBoardScrollPosition] = useState<[number, number] | undefined>(); // [boardId, scrollLeft]
    // Toast уведомления
    const [toasts, setToasts] = useState<Toast[]>([]);
    // Указание какая вкладка в меню подсвечена
    const [navPanelHighlight, setNavPanelHighlight] = useState<HighlightOptions | undefined>();
    const location = useLocation();
    const navigate = useNavigate();
    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const [iconGetNew, setIconGetNew] = useState<any>();
    const [regPersonCheck, setRegPersonCheck] = useState<boolean>(true);
    const [pageTitle, setPageTitle] = React.useState<string>("Task Tracker");
    const [archivedPage, setArchivedPage] = useState<boolean>(false);

    function getSettings() {
        async function fetchAndSetTitle() {
            let pageTitleCurent = "Task Tracker";

            try {
                document.title = "Task Tracker"
                let data = await store.getPublicSettings();
                let iconGet = await  store.getSystemLogo();
                if (iconGet) {
                    setIconGetNew(iconGet);
                } else {
                    setIconGetNew(Favicon);
                }
                if (data?.length) {
                    data.forEach(xx => {
                        console.log(xx.paramName == 'name')
                        if (xx.paramName == 'name') {
                            pageTitleCurent = xx.value?.length ? xx.value : "Task Tracker";
                            setPageTitle(pageTitleCurent);
                        }
                    })
                }
            } catch (err) {
                console.log(err)
            } finally {
                return pageTitleCurent;
            }
        }
        fetchAndSetTitle();
    }

    useLayoutEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        console.log("location.pathname", location.pathname)
        console.log("location.pathname.includes(\"/projects/\")", location.pathname.includes("/projects/"))

        if (location.pathname.includes("/api/activate")) {
            checkUserReg();
        }

        setIsMainPage(
            location.pathname !== "/register"
            && location.pathname !== "/login"
            && location.pathname !== "/forgotPassword"
            && location.pathname !== "/feedback"
            && !location.pathname.includes("/recoverPassword/"));
    }, [])

    function checkUserReg() {
        (async () => {
            try {
                const matchTest = /\/api\/activate\/([a-zA-Z0-9\-]+)/.exec("https://task-test.bpmlab.ru/api/activate/0004244e-6d71-4c80-a5d9-dc3710fab1c2");
                console.log("matchTest", matchTest)

                const match = /\/api\/activate\/([a-zA-Z0-9\-]+)/.exec(location.pathname);
                if (match) {
                    store.setLinkNewPerson(match[1]);
                    const newPerson = await store.getDataLinkPerson(store.linkNewPerson);
                    console.log(newPerson)

                    if (!newPerson) {
                        console.log("Такого пользователя не существует!");
                        return;
                    }

                    store.setUserWithStatus(newPerson);
                    const success: boolean | undefined = await store.logout();

                    if (!success) {
                        console.error("Logout failed");
                    }
                    //
                    console.log(newPerson.status)
                    if (newPerson.status == "Приглашен") {
                        navigate("/register");
                    } else if (newPerson.status == "Ждет активацию") {
                        navigate("/login");
                    }
                }
                setRegPersonCheck(false);
            } catch (err) {
                console.log(err)
            }
        })();
    }

    useEffect(() => {
        (async () => {
            getPermission(undefined);
        })();
    }, [store.isAuth]);

    const getPermission = (functionCode: string[] | undefined) => {
        (async () => {
            try {
                const functionClass = new FunctionSystem()
                const newCheckRole: ICheckRole = {
                    projectId: undefined,
                    boardId: undefined,
                    functionCodes: functionCode != undefined ? functionCode : functionClass.getAppFunction
                };


                const checkThisRole = await store.hasPermission(newCheckRole)
                setUserAccess(checkThisRole);
                return checkThisRole;
            } catch (error) {
                console.log(error)
            }
        })();
    };

    useEffect(() => {
        (async () => {
            if (!store.isActivate)
                await store.checkActivate(store.user.email);
            if (store.isAuth) {
                await store.getCurrentUser();
                await getData();
                await store.getPersonalSettings();
            }
            setIsLoading(false);
        })();
    }, [store.isAuth]);


    useEffect(() => {
        buildBreadCrumbs()
    }, [sideBarProjects]);

    useEffect(() => {
        buildBreadCrumbs();
        setIsMainPage(
            location.pathname !== "/register"
            && location.pathname !== "/login"
            && location.pathname !== "/forgotPassword"
            && location.pathname !== "/feedback"
            && !location.pathname.includes("/recoverPassword/"));
    }, [location.pathname]);


    useEffect(() => {
        //Этот кусок кода заполняет переменные из стора currentBoardData и currentProjectId используя информацию из breadCrumd
        if (breadCrumb && breadCrumb[breadCrumb.length - 1]?.url?.includes('board')) {
            store.setCurrentBoardData(breadCrumb[breadCrumb.length - 1]);
            const currentProjectUrl = breadCrumb[breadCrumb.length - 2].url;
            const currentProjectId: number = Number(currentProjectUrl.split('/')[2]);
            store.setCurrentProjectId(currentProjectId)
        }

        if (breadCrumb && breadCrumb[breadCrumb.length - 1]?.url?.includes('project')) {
            const currentProjectUrl = breadCrumb[breadCrumb.length - 1].url;
            const currentProjectId: number = Number(currentProjectUrl.split('/')[2]);
            store.setCurrentProjectId(currentProjectId)
        }
    }, [breadCrumb]);


    // На основе location.pathname составляет путь хлебных крошек для страниц проекта или доски
    // Для других страниц проще составлять хлебные крошки в их коде, см. страницу Task
    const buildBreadCrumbs = () => {
        let breadCrumbs: IBreadCrumb[] = [];
        let isArchivedPage = false;
        if (location.pathname.includes("archive")) {
            isArchivedPage = true;
            let [_, entity, id] = location.pathname.slice(1).split('/');
            breadCrumbs = [
                {
                    label: "Архив",
                    url: "/archive",
                },
            ];
            if (entity === "project") {
                breadCrumbs = [...breadCrumbs, ...findProject(archivedProjects, Number(id), true)];
            } else if (entity === "board") {
                breadCrumbs = [...breadCrumbs, ...findBoard(archivedProjects, Number(id), true)];
            }
            setBreadCrumb(breadCrumbs);
        } else if (((location.pathname.includes("board") && !location.pathname.includes("task")) || (location.pathname.includes("project") && !location.pathname.includes("projects")))) {
            setNavPanelHighlight(undefined);
            let [entity, id] = location.pathname.slice(1).split('/');
            breadCrumbs = [
                {
                    label: "Проекты",
                    url: "/projects",
                },
            ];
            if (entity === "project") {
                breadCrumbs = [...breadCrumbs, ...findProject(sideBarProjects, Number(id), false)];
            } else if (entity === "board") {
                breadCrumbs = [...breadCrumbs, ...findBoard(sideBarProjects, Number(id), false)];
            }
            setBreadCrumb(breadCrumbs);
        } else if (location.pathname.includes("projects")) {
            setNavPanelHighlight("projects");
            breadCrumbs = [
                {
                    label: "Проекты",
                    url: "/projects",
                },
            ];
            setBreadCrumb(breadCrumbs);
        } else if (!location.pathname.includes("task")) {
            setNavBarInfo({openProjectIds: navBarInfo.openProjectIds});
            setBreadCrumb(breadCrumb);
        }
        setArchivedPage(isArchivedPage);
        if (isArchivedPage)
            setNavPanelHighlight("archive");
    }

    // Ищет проект и подпроект и возвращает массив хлебных крошек
    // Дополнительно подсвечивает и раскрывает нужные пункты меню
    const findProject = (projects: ISideBarProject[], id: number, archivedPage: boolean): IBreadCrumb[] => {
        const breadCrumbs: IBreadCrumb[] = [];
        let project = projects.find(p => p.id === id);
        let subproject: ISideBarSubproject | undefined;
        if (!project) {
            subproject = projects
                .flatMap(project => [...(project.subprojects || [])])
                .find(item => item.id === id);
            if (subproject) {
                project = projects.find(p => p.subprojects?.includes(subproject!));
            }
        }
        if (project) {
            breadCrumbs.push(
                {
                    label: project.name,
                    url: `${archivedPage ? "/archive" : ""}/project/` + project.id
                })
        }
        if (subproject) {
            breadCrumbs.push(
                {
                    label: subproject.name,
                    url: `${archivedPage ? "/archive" : ""}/project/` + subproject.id
                })
        }
        let navBarInfoCopy = structuredClone(navBarInfo);
        navBarInfoCopy.projectId = project?.id;
        navBarInfoCopy.boardId = undefined;
        navBarInfoCopy.subprojectId = subproject?.id;
        if (project) {
            if (!navBarInfoCopy.openProjectIds)
                navBarInfoCopy.openProjectIds = [];
            if (!navBarInfoCopy.openProjectIds?.includes(project.id))
                navBarInfoCopy.openProjectIds?.push(project.id);
        }
        if (subproject) {
            if (!navBarInfoCopy.openProjectIds)
                navBarInfoCopy.openProjectIds = [];
            if (!navBarInfoCopy.openProjectIds?.includes(subproject.id))
                navBarInfoCopy.openProjectIds?.push(subproject.id);
        }
        setNavBarInfo(navBarInfoCopy);
        return breadCrumbs
    }

    // Ищет проект, подпроект (опционально) и доску и возвращает массив хлебных крошек
    // Дополнительно подсвечивает и раскрывает нужные пункты меню
    const findBoard = (projects: ISideBarProject[], id: number, archivedPage: boolean): IBreadCrumb[] => {
        const breadCrumbs: IBreadCrumb[] = [];
        let board: ISideBarBoard | undefined = undefined;
        let subproject: ISideBarSubproject | undefined = undefined;
        let project: ISideBarProject | undefined = undefined;
        let parentBoard: ISideBarBoard | undefined = undefined;
        for (const p of projects) {
            if (board)
                break;
            for (const b of p.boards)
                if (b.id === id) {
                    board = b;
                } else if (b.boards?.find(sb => sb.id === id)) {
                    parentBoard = b;
                    board = b.boards?.find(sb => sb.id === id)
                }
            if (board) {
                project = p;
                break;
            } else if (p.subprojects) {
                for (const sp of p.subprojects) {
                    for (const b of sp.boards)
                        if (b.id === id) {
                            board = b;
                        } else if (b.boards?.find(sb => sb.id === id)) {
                            parentBoard = b;
                            board = b.boards?.find(sb => sb.id === id)
                        }
                    if (board) {
                        subproject = sp;
                        project = p;
                        break;
                    }
                }
            }
        }
        if (project)
            breadCrumbs.push({
                label: project.name,
                url: `${archivedPage ? "/archive" : ""}/project/` + project.id
            })
        if (subproject)
            breadCrumbs.push({
                label: subproject.name,
                url: `${archivedPage ? "/archive" : ""}/project/` + subproject.id
            })
        if (board)
            breadCrumbs.push({
                label: board.name,
                url: `${archivedPage ? "/archive" : ""}/board/` + board.id
            })
        let navBarInfoCopy = structuredClone(navBarInfo);
        navBarInfoCopy.projectId = project?.id;
        navBarInfoCopy.subprojectId = subproject?.id;
        navBarInfoCopy.boardId = board?.id;
        if (project) {
            if (!navBarInfoCopy.openProjectIds)
                navBarInfoCopy.openProjectIds = [];
            if (!navBarInfoCopy.openProjectIds?.includes(project.id))
                navBarInfoCopy.openProjectIds?.push(project.id);
        }
        if (subproject) {
            if (!navBarInfoCopy.openProjectIds)
                navBarInfoCopy.openProjectIds = [];
            if (!navBarInfoCopy.openProjectIds?.includes(subproject.id))
                navBarInfoCopy.openProjectIds?.push(subproject.id);
        }
        if (board) {
            if (!navBarInfoCopy.openBoardIds)
                navBarInfoCopy.openBoardIds = [];
            if (!navBarInfoCopy.openBoardIds?.includes(board.id))
                navBarInfoCopy.openBoardIds?.push(board.id);
        }
        if (parentBoard) {
            if (!navBarInfoCopy.openBoardIds)
                navBarInfoCopy.openBoardIds = [];
            if (!navBarInfoCopy.openBoardIds?.includes(parentBoard.id))
                navBarInfoCopy.openBoardIds?.push(parentBoard.id);
        }
        setNavBarInfo(navBarInfoCopy);
        return breadCrumbs;
    }

    const getData = async () => {
        await store.getProjectsForLeftMenu();
        setSideBarProjects(store.sideBar.projects.filter(p => !p.archived));
        setArchivedProjects(store.sideBar.projects.filter(p => p.archived));
    }


    // Раскрыть/Закрыть вкладку проекта в меню
    const toggleProject = (id: number) => {
        let navBarInfoCopy = structuredClone(navBarInfo);
        if (!navBarInfoCopy.openProjectIds)
            navBarInfoCopy.openProjectIds = [];
        if (navBarInfoCopy.openProjectIds?.includes(id)) {
            navBarInfoCopy.openProjectIds = navBarInfoCopy.openProjectIds.filter((i: number) => i !== id);
        } else {
            navBarInfoCopy.openProjectIds?.push(id);
        }
        setNavBarInfo(navBarInfoCopy);
    };

    // Раскрыть/Закрыть вкладку проекта в меню
    const toggleBoard = (id: number) => {
        let navBarInfoCopy = structuredClone(navBarInfo);
        if (!navBarInfoCopy.openBoardIds)
            navBarInfoCopy.openBoardIds = [];
        if (navBarInfoCopy.openBoardIds?.includes(id)) {
            navBarInfoCopy.openBoardIds = navBarInfoCopy.openBoardIds.filter((i: number) => i !== id);
        } else {
            navBarInfoCopy.openBoardIds?.push(id);
        }
        setNavBarInfo(navBarInfoCopy);
    };

    // Функция для отображения Toast уведомлений
    const showToast = (text: string, content?: ReactNode, timeVisible?: number) => {
        const TIME_VISIBLE = timeVisible ?? 4000;
        const id = Date.now();
        setToasts((prevToasts) => [...prevToasts, {id, content, text}]);

        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        }, TIME_VISIBLE);
    };

    const removeToast = (toastId: number): void => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== toastId));
    }

    if (isLoading) {
        return (
            <LoadingPage/>
        )
    }

    return (
        <div className="main__page">
            <AppContext.Provider
                value={{
                    breadCrumb, setBreadCrumb,
                    sideBarProjects, navBarInfo,
                    toggleProject, toggleBoard,
                    findBoard, findProject,
                    getData, navPanelHighlight,
                    setNavPanelHighlight, currentZoom,
                    setCurrentZoom, MAX_ZOOM, MIN_ZOOM,
                    boardScrollPosition, setBoardScrollPosition,
                    // checkRole,
                    showToast, getPermission,
                    pageTitle,
                    archivedPage,
                    archivedProjects
                }}>
                <SEO title={pageTitle}/>
                <HeadProvider>
                    <Link rel="icon" href={iconGetNew} type="image/png"/>
                    <Link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"/>
                </HeadProvider>
                {isMainPage && <MenuForm userAccess={userAccess}/>}

                <div className={`main__page-body ${isMainPage ? "" : "external__page-body"}`}>
                    {isMainPage && <Header userAccess={userAccess}/>}
                    <Routes>
                        <Route path='/login/token' element={<LoginToken/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                        <Route path="/noAccessPages" element={<NoAccessPages/>}/>
                        <Route path="/noAccessPages/:token" element={<NoAccessPages/>}/>
                        <Route path="/recoverPassword/:token" element={<RecoverPassword/>}/>
                        <Route path="/register" element={<Authorization/>}/>
                        <Route path="/*" element={
                            <GuardedRoute condition={store.isAuth && store.isActivate}>
                                <Routes>
                                    <Route path="/archive" element={<Arhive/>}/>
                                    <Route path="/monitor" element={<UserMonitor/>}/>
                                    <Route path="/reference" element={<Reference/>}/>
                                    <Route path="/projects" element={<Projects/>}/>
                                    <Route path="/task/:id" element={<Task/>}/>
                                    <Route path="/archive/task/:id" element={<Task/>}/>
                                    <Route path="/board/:boardId/task/:id" element={<Task/>}/>
                                    <Route path="/archive/board/:boardId/task/:id" element={<Task/>}/>
                                    <Route path="/project/:id" element={<Project/>}/>
                                    <Route path="/archive/project/:id" element={<Project/>}/>
                                    <Route path="/board/:boardId" element={<Board/>}/>
                                    <Route path="/archive/board/:boardId" element={<Board/>}/>
                                    <Route path="/system/settings" element={<SystemSettings/>}/>
                                    <Route path="/feedback" element={<Feedback/>}/>
                                    {/* ProjectsMain - путь по умолчанию если в url - чушь */}
                                    <Route path="*" element={<Projects/>}/>
                                </Routes>
                            </GuardedRoute>
                        }/>
                        <Route path="*" element={<Login/>}/>
                    </Routes>
                    <ToastNotification toasts={toasts} removeToast={removeToast}/>
                </div>

            </AppContext.Provider>
        </div>
    );
}
export default App;
