import React, {FC, useContext, useEffect, useRef, useState, ReactNode, useLayoutEffect} from "react";
import { IMonitorTaskDisplay } from "../../../models/IUserMonitorTaskFullInfo";
import { initials } from "../../../helpers/Inicials";
import { dateToNiceString } from "../../../helpers/dateToNiceString";
import MarkdownPreview from "../../Shared/MarkdownPreview/MarkdownPreview";
import ExitMark from "../../../assets/ButtonIcons/user-monitor-exit-button.svg";
import "./TaskDescription.css"
import {Context} from "../../../index";
import {IGetTaskHistoryDisplay, ITaskHistoryFilter} from "../../../models/TaskModels";
import {IProjectHistoryDisplay} from "../../../models/ProjectModels";
import UserPopupWrapper from "../../Shared/UserPopup/UserPopupWrapper";
import { Link } from "react-router-dom";
import { RenderValue } from "../../Shared/HistoryTable/ProjectHistoryMapper";
import RightArrow from "../../../assets/rightArrow.svg";
import {ICheckRole, IHasPermission} from "../../../models/IChekRole";
import FunctionSystem from "../../../models/functionCode/functionsSystem";
import {DELETE} from "mobx/dist/types/observablemap";
import {start} from "node:repl";

interface ITaskDescriptionProps {
    task: IMonitorTaskDisplay;
    setSelectedTaskId: (taskId: number | undefined) => void;
}

const TaskDescription: FC<ITaskDescriptionProps> = ({
    task,
    setSelectedTaskId,
}) => {
    const { store } = useContext(Context);
    const [showFullComment, setShowFullComment] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showToggleDescription, setShowToggleDescription] = useState(false);
    const [showToggleComment, setShowToggleComment] = useState(false);
    const [historyTask, setHistoryTask] = useState<IProjectHistoryDisplay[]>()
    const commentRef = useRef<HTMLDivElement | null>(null);
    const descriptionRef = useRef<HTMLDivElement | null>(null);
    const [scrollEndedHistory, setScrollEndedHistory] = useState(false);
    //Доступы
    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const [viewTask, setViewTask] = useState<boolean>(false)
    const [viewChat, setViewChat] = useState<boolean>(false)
    const [viewFile, setViewFile] = useState<boolean>(true)
    const OVERFLOW_HEIGHT = 200;

    const [closedRows, setClosedRows] = useState<string[]>([]);
    const onMinimizeButtonClick = (elem: string) => {
        if (closedRows.some((r) => r === elem))
            setClosedRows([...closedRows.filter((r) => r !== elem)]);
        else setClosedRows([...closedRows, elem]);
    };

    function toDisplayDate(dateStr: any): string {
        try {
            let date = new Date(dateStr);
            const padWithZero = (value: number) =>
                value.toString().padStart(2, "0");
            const day = padWithZero(date.getDate());
            const month = padWithZero(date.getMonth() + 1);
            const year = date.getFullYear();
            const hours = padWithZero(date.getHours());
            const minutes = padWithZero(date.getMinutes());

            //Перестанет работать в 2100 году :)
            return `${day}.${month}.${year - 2000} ${hours}:${minutes}`;
        } catch (err: any) {
            return "-";
        }
    }


    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem()
                const newCheckRole: ICheckRole = {
                    projectId: task?.project.id,
                    boardId: undefined,
                    functionCodes: functionClass.getTaskFunction
                };

                const checkThisRole = await store.hasPermission(newCheckRole);
                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error)
            }
        })();
    }, [task]);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewTask(true);
                    }
                });
            }
            if (xx.functionCode == "TaskChatAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewChat(true);
                    }
                })
            }
        });
    }, [userAccess]);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskFileAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == false) {
                        let startIndex = 0;
                        let flagHref = false;
                        let twoFlag = false;
                        let saveText = task.lastComments[0].text
                        for (let i = 0; i < task.lastComments[0].text.length; i++) {
                            if (task.lastComments[0].text[i] == "h" && task.lastComments[0].text[i+1] == "r" &&
                                task.lastComments[0].text[i+2] == "e" && task.lastComments[0].text[i+3] == "f") {
                                startIndex = i; flagHref = true;
                            }

                            if (flagHref == true && task.lastComments[0].text[i] == '"') {
                                if (twoFlag == true) {
                                    let saveEnd = saveText.slice(i, saveText.length)
                                    saveText = saveText.slice(0, startIndex) + saveEnd;
                                    twoFlag = false;
                                } else {
                                    twoFlag = true;
                                }
                            }
                        }
                        task.lastComments[0].text = saveText;

                        setViewFile(false);
                    }
                })
            }
        });
    }, [task, userAccess]);

    useEffect(() => {
        const checkCommentsHeight = () => {
            if (commentRef.current) {
                setShowToggleComment(
                    commentRef.current.scrollHeight > OVERFLOW_HEIGHT
                );
            }
            else {
                setShowToggleComment(false);
            }

            if (descriptionRef.current) {
                setShowToggleDescription(
                    descriptionRef.current.scrollHeight > OVERFLOW_HEIGHT
                );
            }
            else {
                setShowToggleDescription(false);
            }
                
        };

        (async () => {
            let getHistory: IGetTaskHistoryDisplay = {
                skip: 0,
                take: 3,
                filters: {
                    taskId: task.id
                },
                sort: {
                    date: 'asc'
                }
            }
            try {
                const data = await store.getTaskHistory(getHistory);
                if (data && data?.length) {
                    setHistoryTask(data);
                }
                console.log(data)
            } catch (error) {
                console.error("Error fetching task history:", error);
            }
        })();

        checkCommentsHeight();
        window.addEventListener("resize", checkCommentsHeight);

        return () => {
            window.removeEventListener("resize", checkCommentsHeight);
        };
    }, [task, viewTask]);

    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e)
    };

    const appendTeamMembers = async () => {
        let getHistory: IGetTaskHistoryDisplay = {
            skip: historyTask != undefined ? historyTask.length : 0,
            take: 5,
            filters: {
                taskId: task.id
            },
            sort: {
                date: 'asc'
            }
        }

        const data = await store.getTaskHistory(getHistory);
        if (data && data?.length > 0) {
            if (historyTask != undefined) {
                setHistoryTask([...historyTask, ...data]);
            } else {
                setHistoryTask(data);
            }
        } else { //TODO check res status
            setScrollEndedHistory(true)
        }
    }

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if ((1 + scrollTop + offsetHeight >= scrollHeight) && (!scrollEndedHistory)) {
            (async () => {
                appendTeamMembers()
            })();
        }
    }
    

    return (
        <div>
            {viewTask ? (
                <div>
                    <div className="exit-button" onClick={() => {
                        setSelectedTaskId(undefined);
                    }}>
                        <img src={ExitMark} alt="Закрыть задачу"/>
                    </div>
                    <div className="right-bar-top-header break-word">{task.name}</div>
                    <div className="user-monitor-right-bar-block">
                        <div ref={descriptionRef} id="full-description-foldable-field"
                             className={`block-text ${showToggleDescription ? showFullDescription ? "" : "folded" : ""}`}>
                            {/*<MarkdownPreview content={} />*/}
                            <div dangerouslySetInnerHTML={{__html: task.description ?? ""}}/>
                        </div>
                        {showToggleDescription ? (
                            <p className="show-all"
                               onClick={(event) => {
                                   setShowFullDescription(!showFullDescription);
                               }}
                            >
                                {showFullDescription
                                    ? "Свернуть"
                                    : "Показать полностью"}
                            </p>
                        ) : null}
                    </div>
                    <div className="user-monitor-right-bar-block-divider"></div>
                    <div className="user-monitor-right-bar-block user-monitor-right-bar-block_last-comment">
                        <div className="block-name">ПОСЛЕДНИЕ КОММЕНТАРИИ</div>
                        {viewChat ? (
                            task.lastComments[0] ?
                            <div ref={commentRef} className="last-comments-block">
                                <div className="author">
                                    <div className="name">
                                        {initials(
                                            task.lastComments[0].author.surname +
                                            " " +
                                            task.lastComments[0].author.name +
                                            " " +
                                            task.lastComments[0].author.middlename
                                        )}
                                    </div>
                                    <div
                                        className="relation-to-task">{task.lastComments[0].author.positionInRelationToTask}</div>
                                </div>
                                <div className="date">
                                    {dateToNiceString(
                                        new Date(task.lastComments[0]?.date),
                                        "month dd, yyyy"
                                    )}
                                </div>
                                <div id="last-comment-foldable-field"
                                     className={`comment ${
                                         showToggleComment
                                             ? showFullComment
                                                 ? ""
                                                 : "folded"
                                             : ""
                                     }`}
                                >
                                    {/*<MarkdownPreview*/}
                                    {/*    content={task.lastComments[0]?.text}*/}
                                    {/*/>*/}
                                    <div dangerouslySetInnerHTML={{__html: task.lastComments[0]?.text}}/>
                                </div>
                            </div>
                                :
                                <div className="user-monitor-right-bar-block-hide">
                                    <h2>Нет комментариев</h2>
                                </div>
                        ) : (
                            <div className="user-monitor-right-bar-block-hide">
                                <h2>У вас нет прав на просмотр комментариев</h2>
                            </div>
                        )}
                        {showToggleComment ? (
                            <p
                                className="show-all"
                                onClick={(event) => {
                                    setShowFullComment(!showFullComment);
                                }}
                            >
                                {showFullComment ? "Свернуть" : "Показать полностью"}
                            </p>
                        ) : null}
                    </div>
                    <div className="user-monitor-right-bar-block-divider"></div>
                    <div className="user-monitor-right-bar-block_block-name">ПОСЛЕДНИЕ ИЗМЕНЕНИЯ</div>
                    <div className="user-monitor-right-bar-block user-monitor-right-bar-block_last-edit" onScroll={onScroll}>
                        {historyTask?.length ? historyTask.map((xx, index) => (
                                <div className="user-monitor-right-bar-block-history">
                                    <div className="user-monitor-right-bar-block-history-head">
                                        <div className="user-monitor-right-bar-block-history_name">
                                            {initials(xx.createdBy.surname + " " + xx.createdBy.name + " " + xx.createdBy.middlename)}
                                        </div>
                                    </div>
                                    <div className="user-monitor-right-bar-block-history_date">
                                        {dateToNiceString(new Date(xx?.createdAt), "month dd, yyyy")}
                                    </div>
                                    <div className="user-monitor-right-bar-block-history_data">
                                        {xx.changeDataDifference.map((diff) => (
                                            <div className="user-monitor-right-bar-block-history_data-block" style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                wordBreak: "break-all",
                                                alignItems: "center",
                                                height: "26px"
                                            }}>
                                                {diff.caption ? (
                                                    <span style={{fontWeight: "400", marginRight: "5px", width: "100px"}}>
                                                    {diff.caption + ""}
                                                </span>
                                                ) : null}
                                                <RenderValue
                                                    type={diff.type}
                                                    value={diff.oldValue}
                                                    showFullChanges={false
                                                        // showFullChanges ?? false
                                                    }
                                                    minimized={
                                                        !closedRows.some(
                                                            (r) =>
                                                                r ===
                                                                index +
                                                                diff.caption +
                                                                "old"
                                                        )
                                                    }
                                                    onMinimizeButtonClick={() => {
                                                        onMinimizeButtonClick(
                                                            index +
                                                            diff.caption +
                                                            "old"
                                                        );
                                                    }}
                                                />
                                                {diff.oldValue || diff.newValue ? (
                                                    <img
                                                        style={{
                                                            alignSelf: "center",
                                                        }}
                                                        src={RightArrow}
                                                        alt=""
                                                        height={10}
                                                    />
                                                ) : null}
                                                <RenderValue
                                                    type={diff.type}
                                                    value={diff.newValue}
                                                    showFullChanges={false
                                                        // showFullChanges ?? false
                                                    }
                                                    minimized={
                                                        !closedRows.some(
                                                            (r) =>
                                                                r ===
                                                                index +
                                                                diff.caption +
                                                                "new"
                                                        )
                                                    }
                                                    onMinimizeButtonClick={() => {
                                                        onMinimizeButtonClick(
                                                            index +
                                                            diff.caption +
                                                            "new"
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )) :
                            <div>

                            </div>
                        }
                    </div>
                </div>
            ) : (
                <div className="user-monitor-right-bar-block-hide">
                    <h2>У вас нет прав на просмотр задачи!</h2>
                </div>
            )}
        </div>
    );
};

export default TaskDescription;
