import { FC, useContext, useEffect, useState } from "react";
import { ISideBarSubproject } from "../../../models/LeftMenuModels";
import { Link } from "react-router-dom";
import SubprojectIcon from "../../../assets/subproject.svg";
import SubprojectIconBlue from "../../../assets/subproject_blue.svg";
import { Context } from "../../..";
import BoardComponent from "./BoardComponent";
import "./NavigationPanel.css";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import { AppContext } from "../../../App";

interface ISubprojectComponentProps {
    subproject: ISideBarSubproject;
    showProxyBoards: boolean;
}

const SubprojectComponent: FC<ISubprojectComponentProps> = ({ subproject, showProxyBoards }) => {
    const [highlighted, setHighlighted] = useState<boolean>(false);
    const { toggleProject, navBarInfo } = useContext(AppContext);
    const { store } = useContext(Context);

    useEffect(() => {
        setHighlighted(navBarInfo.subprojectId === subproject.id);
    }, [navBarInfo, subproject]);

    const handleClick = () => {
        if (highlighted)
            toggleProject(subproject.id);
    }

    return (
        <div className="nav-panel_subproject-container">
            <Link
                className={`nav-panel_subproject ${
                    highlighted ? "nav-panel_highlighted-row" : ""
                } ${navBarInfo.openProjectIds !== undefined &&
                    navBarInfo.openProjectIds?.includes(subproject.id) ? "open" : ""}`}
                to={"/project/" + subproject.id}
                onClick={handleClick}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        className="nav-panel_suproject-image"
                        src={highlighted ? SubprojectIconBlue : SubprojectIcon}
                        alt="subproject-icon"
                    />
                    <div className={`nav-panel_label`}>{subproject.name}</div>
                </div>
                {subproject.boards ? (
                    <ArrowButton
                        onClick={(e) => {
                            e.preventDefault();
                            toggleProject(subproject.id);
                        }}
                        open={
                            navBarInfo.openProjectIds !== undefined &&
                            navBarInfo.openProjectIds?.includes(subproject.id)
                        }
                    />
                ) : null}
            </Link>
            {subproject.boards &&
            navBarInfo.openProjectIds !== undefined &&
            navBarInfo.openProjectIds?.includes(subproject.id)
                ? 
                <div className="nav-panel_subproject_children">
                    {subproject.boards
                        .filter((b) => showProxyBoards || !b.isProxyBoard)
                        .map((board) => (
                            <div
                                className="nav-panel_subproject-board"
                                key={board.id}
                            >
                                <BoardComponent board={board} showProxyBoards={showProxyBoards}/>
                            </div>
                        ))}
                </div>
                : null}
        </div>
    );
};

export default SubprojectComponent;
