import React, {FC, useContext, useEffect, useState} from 'react';
import "./TaskChecklist.css";
import {IHasPermission} from "../../../models/IChekRole";
import {ITaskChecklistGroup} from "../../../models/ITaskChecklistGroup";
import {ITaskChecklist} from "../../../models/ITaskChecklist";
import {Context} from "../../../index";
import {useParams} from "react-router-dom";
import TaskChecklistGroup from "../TaskChecklistGroup/TaskChecklistGroup";
import {ITaskChecklistGroupEdit} from "../../../models/ITaskChecklistGroupEdit";
import AddElement from "../../../assets/addTaskInColumn.svg";
import {AppContext} from "../../../App";


interface userAccess {
    userAccess: IHasPermission[]
}

interface handleEditChecklistGroup {
    checklistGroupId: number,
    newName?: string,
    newPosition?: number
}

const TaskChecklist: FC<userAccess> = ({userAccess}) => {
    const {store} = useContext(Context);
    const {showToast} = useContext(AppContext);
    const {id} = useParams();
    const [checklistGroups, setCheklistGroups] = useState<ITaskChecklistGroup[]>([]);
    const [showAddGroupInput, setShowAddGroupInput] = useState<boolean>(false);
    const [showAddElementInput, setShowElementInput] = useState<boolean>(false);
    const [editTask, setEditTask] = useState<boolean>(false);
    const [nameGroup, setNameGroup] = useState<string>();
    const [nameElement, setNameElement] = useState<string>();


    // const {checklistGroup, setCheklistGroup}=useState<ITaskChecklistGroup[]>()
    //drag const
    const [dragEventTarget, setDragEventTarget] = useState<any>();
    const [previewChecklistGroupIndex, setPreviewChecklistGroupIndex] = useState<
        number | undefined
    >();

    const removePreview = () => {
        const elements = document.querySelectorAll(".column-preview");
        elements.forEach((element) => element.remove());
        setPreviewChecklistGroupIndex(undefined);
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragEventTarget(e.target);
    };

    const createPreviewElement = (content: string) => {
        let newElement = document.createElement("div");
        newElement.className = "column-preview";
        newElement.innerHTML = content;
        newElement.style.backgroundColor = "#149cce6b";
        newElement.style.padding = "25px";
        newElement.style.borderRadius = "6px";
        newElement.style.pointerEvents = "none";
        return newElement;
    };

    // const handleMoveColumn = async (columnId: number, position: number) => {
    //     const moveColumnData: IMoveColumn = {
    //         boardId: Number(boardId),
    //         columnId: columnId,
    //         position: position,
    //     };
    //     await store.moveColumn(moveColumnData);
    //     await store.getAllColumnsBoard(Number(boardId));
    //     setColumns(store.columns);
    // };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const droppedPosition = e.clientY;

        let insertIndex = checklistGroups.length;

        for (let checklistGroup of checklistGroups) {
            const checklistGroupElement = document.getElementById(
                `group-${checklistGroup.id}`
            );
            if (checklistGroupElement) {
                const rect = checklistGroupElement.getBoundingClientRect();
                const midY = rect.top + rect.height / 2;
                if (droppedPosition < midY) {
                    insertIndex = checklistGroups.indexOf(checklistGroup);
                    break;
                }
            }
        }

        if (previewChecklistGroupIndex === insertIndex) return;

        removePreview();

        if (insertIndex < checklistGroups.length) {
            const groupElement = document.getElementById(
                `group-${checklistGroups[insertIndex].id}`
            );
            if (groupElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                groupElement.insertAdjacentElement("beforebegin", newElement);
                setPreviewChecklistGroupIndex(insertIndex);
            }
        } else {
            const groupElement = document.getElementById(
                `group-${checklistGroups[checklistGroups.length - 1].id}`
            );
            if (groupElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                groupElement.insertAdjacentElement("afterend", newElement);
                setPreviewChecklistGroupIndex(insertIndex);
            }
        }
    };

    const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
        console.log('test2')
        e.preventDefault();
        try {
            console.log('e.dataTransfer', e.dataTransfer.getData("application/json"))
            const {checklistGroupId} = JSON.parse(
                e.dataTransfer.getData("application/json")
            );

            const droppedPosition = e.clientY;
            console.log('checklistGroupId', checklistGroupId)

            const movedChecklistGroup = checklistGroups.find((c) => c.id === checklistGroupId);
            if (!movedChecklistGroup) return;
            console.log(movedChecklistGroup)

            let insertIndex = checklistGroups.length;

            for (let checklistGroup of checklistGroups) {
                const groupElement = document.getElementById(
                    `group-${checklistGroup.id}`
                );
                if (groupElement) {
                    const rect = groupElement.getBoundingClientRect();
                    const midY = rect.top + rect.height / 2;
                    if (droppedPosition < midY) {
                        insertIndex = checklistGroups.indexOf(checklistGroup);
                        console.log(insertIndex)

                        break;
                    }
                }
            }

            if (insertIndex !== checklistGroups.indexOf(movedChecklistGroup)) insertIndex--;
            if (insertIndex < checklistGroups.indexOf(movedChecklistGroup)) insertIndex++;

            if (insertIndex === checklistGroups.indexOf(movedChecklistGroup)) {
                return;
            }
            const checklistGroupEdit: handleEditChecklistGroup = {
                checklistGroupId: checklistGroupId,
                newPosition: insertIndex

            }


            editGroup(checklistGroupEdit)
            // handleMoveColumn(checklistGroupId, insertIndex);
            removePreview();
        } catch (err) {
            console.log(err)
            removePreview();
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragEventTarget !== e.target) {
            removePreview();
        }
    };

    const handleDragEnd = () => {
        removePreview();
    };

    // функции связанные с чеклистом
    const loadChecklist = async () => {
        let res = await store.getTaskChecklist(Number(id))
        setCheklistGroups(res!)
    }

    useEffect(() => {
        (async () => {

            try {
                await loadChecklist()
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    useEffect(() => {
        try {
            userAccess.forEach((xx) => {
                if (xx.functionCode == "TaskAction") { //Информация по задаче
                    xx.permissions.forEach((yy) => {
                        // if (yy.permissionCode == "view" && yy.isGranted == true) {
                        //     setViewTaskChatMessage(true);
                        // }
                        // if (yy.permissionCode == "create" && yy.isGranted == true) {
                        //     setCreateTaskChatMessage(true);
                        // }
                        if (yy.permissionCode == "edit" && yy.isGranted == true) {
                            setEditTask(true);
                        }
                        // if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        //     setDeleteTaskChatMessage(true);
                        // }
                    })
                }
                // if (store.user.email == "admin@admin.adm") {
                //     setViewTaskChatMessage(true);
                //     setCreateTaskChatMessage(true);
                //     setDeleteTaskChatMessage(true);
                //     setEditTaskChatMessage(true);
                // }
            })
        } catch (err) {
            console.log(err)
        }
    }, [userAccess]);


    function addGroupInputShow() {
        setShowAddGroupInput(!showAddGroupInput)
        if (showAddElementInput) setShowElementInput(!showAddElementInput)
    }

    function addElementInputShow() {
        setShowElementInput(!showAddElementInput)
        if (showAddGroupInput) setShowAddGroupInput(!showAddGroupInput)
    }

    const addName = (e: any) => {
        console.log(e.target.value);
        if (e.target.value.length > 0) {
            if (showAddGroupInput) {
                setNameGroup(e.target.value);
            } else if (showAddElementInput) {
                setNameElement(e.target.value);
            }
        }
    };


    async function create() {
        if (showAddGroupInput) {
            if (nameGroup?.length) {
                const group: ITaskChecklistGroup = {
                    id: -1,
                    name: nameGroup,
                    position: 0,
                    taskId: Number(id!),
                }
                await store.createTaskChecklistGroup(group);
                await loadChecklist();
                setNameGroup('')
            }
        } else if (showAddElementInput) {
            await createElement()
        }
        setShowAddGroupInput(false)
        setShowElementInput(false)
    }

    async function createElement(groupId?: number, newElementName?: string) {
        const name = nameElement?.trim().length ? nameElement : newElementName?.trim().length ? newElementName : undefined;
        if (name) {
            const element: ITaskChecklist = {
                id: -1,
                text: name,
                position: 0,
                checked: false,
                taskId: Number(id!),
                groupId: groupId ?? checklistGroups.find(xx => xx.position === 0)?.id,
            }
            await store.createChecklistElement(element);
            await loadChecklist();
            setNameElement('');
        }
    }


    const editGroup = (async (handleEditChecklistGroup: handleEditChecklistGroup) => {
        if (checklistGroups?.length) {
            let checklistGroup = checklistGroups.find(xx => xx.id === handleEditChecklistGroup.checklistGroupId);
            if (checklistGroup) {
                const group: ITaskChecklistGroupEdit = {
                    id: handleEditChecklistGroup.checklistGroupId,
                    name: handleEditChecklistGroup.newName,
                    position: handleEditChecklistGroup.newPosition,
                }
                await store.editTaskChecklistGroup(group);
                await loadChecklist();
            }
        }
    })

    const handleNameChange = async (e: any) => {
        if (e.key === "Enter") {
            await create()
        }
    }

    return (
        <div className={'checklist--body'}>
            <div className={'checklist_button_add--body'}>
                <div className={'checklist_button_add'} onClick={editTask ? addElementInputShow :
                    ()=>showToast("Вы не можете добавлять элементы чек листа пока у вас нет прав на редактирование задачи")}>
                    <div>
                        Добавить пункт
                    </div>
                    <div>
                        <img className={'checklist_button_add--img'} src={AddElement}/>
                    </div>
                </div>
                <div className={'checklist_button_add'} onClick={ editTask ? addGroupInputShow :
                    ()=>showToast("Вы не можете добавлять элементы чек листа пока у вас нет прав на редактирование задачи")}>
                    <div>
                        Добавить группу
                    </div>
                    <div>
                        <img className={'checklist_button_add--img'} src={AddElement}/>
                    </div>
                </div>
            </div>
            {showAddGroupInput || showAddElementInput ?
                <div className={showAddGroupInput ? 'task_checklist_group--input' : 'task_checklist_elem--input'}>
                    <div
                        className={showAddGroupInput ? "task_checklist_group__block--name-add" : "task_checklist_elem__block--name-add"}>
                        <input type={"text"} onInput={addName} onKeyDown={handleNameChange}
                               placeholder={showAddGroupInput ? 'Введите название группы' : "Введите название пункта"}/>
                    </div>

                </div> : null
            }
            <div className="task_checklist_groups" onDragEnter={handleDragEnter}
                 onDragOver={(e) => handleDragOver(e)}
                 onDrop={(e) => handleOnDrop(e)}
                 onDragLeave={handleDragLeave}>


                {checklistGroups?.sort((a, b) => a.position - b.position).map((c, index) => (
                    <TaskChecklistGroup key={c.id}
                                        checklistGroup={c}
                                        onChecklistGroupDragEnd={handleDragEnd}
                                        draggable={index !== 0}
                                        deletable={index !== 0}
                                        userAccess={userAccess}
                                        editGroup={editGroup}
                                        loadChecklist={loadChecklist}
                                        createElement={createElement}
                    />
                ))}
            </div>
        </div>
    );
};

export default TaskChecklist;