import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import "./InputChat.css";
import SimpleMDEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {Context} from "../../..";
import {useParams} from "react-router-dom";
import {format} from "date-fns";
import {IHasPermission} from "../../../models/IChekRole";
import {AppContext} from "../../../App";
import unknown_svg from "../../../assets/FileIcons/unknown.svg";
import {IMessage, IMessageGroup} from "../../../models/IChatMessage";
import {equalDMY} from "../../../helpers/compareDates";
import {TaskContext} from "../../../pages/Task/Task";
import {FileIcons} from "./FileIcons";
import {convertMessage, formatEditMessage, generateFileId} from "../../../helpers/chatFucntion"
import {fullNameFormat} from "../../../helpers/Inicials";
import Avatar from "../Avatar/Avatar";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import UserPopupWrapper from "../UserPopup/UserPopupWrapper";
import Loader from "../Loader/Loader";

type ToolbarButton =
    | "bold"
    | "italic"
    | "quote"
    | "unordered-list"
    | "ordered-list"
    | "link"
    | "image"
    | "strikethrough"
    | "code"
    | "table"
    | "redo"
    | "heading"
    | "undo"
    | "heading-bigger"
    | "heading-smaller"
    | "heading-1"
    | "heading-2"
    | "heading-3"
    | "clean-block"
    | "horizontal-rule"
    | "preview"
    | "side-by-side"
    | "fullscreen"
    | "guide";

interface Options {
    showIcons?: ReadonlyArray<ToolbarButton>;
}

interface IInputChat {
    userAccess: IHasPermission[];
    chatId: number;
    setMessagesCount? : React.Dispatch<React.SetStateAction<number>>
}

const InputChat: FC<IInputChat> = ({userAccess, chatId, setMessagesCount}) => {
    const {store} = useContext(Context);
    const [fileMap, setFileMap] = useState<{
        data: { type: string; id: string; url: string }[];
    }>({data: []});
    const [edit, setEdit] = useState(false);
    const [idEditMessage, setIdEditMessage] = useState(-1);
    const [messageGroups, setMessageGroups] = useState<IMessageGroup[]>([]);

    const [editValue, setEditValue] = useState("");
    const [value, setValue] = useState("");

    const {showToast} = useContext(AppContext);

    const {scrollComponentDiv} = useContext(TaskContext);
    const [editedMessageDiv, setEditedMessageDiv] = useState<HTMLElement>();

    const [viewTaskChatMessage, setViewTaskChatMessage] = useState(false);
    const [createTaskChatMessage, setCreateTaskChatMessage] = useState(false);
    const [editTaskChatMessage, setEditTaskChatMessage] = useState(false);
    const [deleteTaskChatMessage, setDeleteTaskChatMessage] = useState(false);

    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        try {
            userAccess.forEach((xx) => {
                if (xx.functionCode == "TaskChatAction") {
                    //Информация по задаче
                    xx.permissions.forEach((yy) => {
                        if (yy.permissionCode == "view" && yy.isGranted == true) {
                            setViewTaskChatMessage(true);
                        }
                        if (yy.permissionCode == "create" && yy.isGranted == true) {
                            setCreateTaskChatMessage(true);
                        }
                        if (yy.permissionCode == "edit" && yy.isGranted == true) {
                            setEditTaskChatMessage(true);
                        }
                        if (yy.permissionCode == "delete" && yy.isGranted == true) {
                            setDeleteTaskChatMessage(true);
                        }
                    });
                }
            });
        } catch (err) {
            console.log(err);
        }
    }, [userAccess]);

    const loadChatMessages = async () => {
        let groups = await store.getChatMessages(chatId);
        if (groups) {
            groups.forEach(group => {
                group.messages = group.messages.sort((a, b) =>
                    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            })
            groups = groups.sort(
                (a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime()
            );
            if (setMessagesCount)
                setMessagesCount(groups.flatMap(g => g.messages).length)
            setMessageGroups(groups);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                await loadChatMessages();
            } catch (error) {
                console.log(error);
            }
        })();
    }, [chatId]);

    function getCurrentDate(messageDateCur: string) {
        const messageDate = new Date(messageDateCur);
        const today = new Date();
        const yesterday = new Date(today);
        const dayBeforeYesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        dayBeforeYesterday.setDate(today.getDate() - 2);

        if (equalDMY(messageDate, today)) {
            return "Сегодня";
        } else if (equalDMY(messageDate, yesterday)) {
            return "Вчера";
        } else if (equalDMY(messageDate, dayBeforeYesterday)) {
            return "Позавчера";
        } else {
            return `${messageDate.getDate().toString().padStart(2, "0")}.${(
                messageDate.getMonth() + 1
            )
                .toString()
                .padStart(2, "0")}.${messageDate.getFullYear()}`;
        }
    }

    function isToday(): boolean {
        if (!messageGroups.length)
            return false;
        const today = new Date();
        const lastGroup = messageGroups[messageGroups.length - 1];
        const lastDayInChat = lastGroup?.messages[lastGroup.messages?.length - 1];
        const lastDateInChat = new Date(lastDayInChat?.createdAt);

        return !equalDMY(lastDateInChat, today);
    }

    useEffect(() => {
        const boldElement: HTMLButtonElement | null =
            document.querySelector(".bold");
        if (boldElement) {
            boldElement.title = "Жирный (Ctrl+B)";
        }
        const italicElement: HTMLButtonElement | null =
            document.querySelector(".italic");
        if (italicElement) {
            italicElement.title = "Курсив (Ctrl+I)";
        }
        const headingElement: HTMLButtonElement | null =
            document.querySelector(".heading");
        if (headingElement) {
            headingElement.title = "Заголовок (Ctrl+H)";
        }
        const codeElement: HTMLButtonElement | null =
            document.querySelector(".code");
        if (codeElement) {
            codeElement.title = "Код (Ctrl+Alt+C)";
        }
        const quoteElement: HTMLButtonElement | null =
            document.querySelector(".quote");
        if (quoteElement) {
            quoteElement.title = "Кавычки (Ctrl+')";
        }
        const unorderedListElement: HTMLButtonElement | null =
            document.querySelector(".unordered-list");
        if (unorderedListElement) {
            unorderedListElement.title = "Неупорядоченный список (Ctrl+B)";
        }
        const orderedListElement: HTMLButtonElement | null =
            document.querySelector(".ordered-list");
        if (orderedListElement) {
            orderedListElement.title = "Упорядоченный список (Ctrl+Alt+L)";
        }
        const horizontalRuleElement: HTMLButtonElement | null =
            document.querySelector(".horizontal-rule");
        if (horizontalRuleElement) {
            horizontalRuleElement.title = "Горизонтальная линия";
        }
        const undoElement: HTMLButtonElement | null =
            document.querySelector(".undo");
        if (undoElement) {
            undoElement.title = "Назад (Ctrl+Z)";
        }
        const redoElement: HTMLButtonElement | null =
            document.querySelector(".redo");
        if (redoElement) {
            redoElement.title = "Вперед (Ctrl+Y)";
        }
    }, [userAccess]);

    const saveEditMessage = () => {
        (async () => {
            await store.saveEditMessage(editValue, idEditMessage);
            setFileMap({data: []});
            setValue("");
            await loadChatMessages();
            setEdit(false);
            setEditValue("");
            setIdEditMessage(-1);
            scrollToMessage();
        })();
    };

    function deleteMessage(idMessage: number) {
        if (deleteTaskChatMessage) {
            (async () => {
                await store.deleteMessage(idMessage);
                await loadChatMessages();
            })();
        } else {
            showToast("У вас нет прав на удаление комментариев");
        }
    }

    // const generateFileId = (fileType: string) => {
    //     const id = Date.now().toString(); //.slice(0,5);
    //     return `${fileType}${id}`;
    // };

    //Все что выше можно не трогать

    // useEffect(() => {
    //     replaceHtml(value);
    // }, [fileMap, value, id]);

    //Обработка файлов
    function fileIcon(fileType: string | undefined) {
        let fileTypeClean = fileType?.replace(/\./g, "");
        let variableName = `${fileTypeClean}_svg`;
        let pictureSrc = (FileIcons as any)[variableName];
        if (!pictureSrc) {
            //Тут если не нашли картинку нужна женерик картинка файла
            return unknown_svg;
        } else {
            return pictureSrc;
        }
    }

    const imageUpload = async (file: any, onSuccess: any, onError: any) => {
        try {
            const res = await store.addFileChat(file, chatId); // Поменять на чат
            const url = getFileUrlFromId(res.id);

            store.setUpdateFile(true);
            let fileType = "F";

            if (file.type.includes("image")) {
                fileType = "G";
            } else if (file.type.includes("audio")) {
                fileType = "M";
            } else if (file.type.includes("video")) {
                fileType = "V";
            }

            let fileTypeGet = file.name.split(".");
            let imgGet = fileIcon(fileTypeGet[fileTypeGet.length - 1]);
            let fileName =
                file.name.length > 10
                    ? file.name.substring(0, 10) + "..."
                    : file.name;

            let dataSave =
                fileType === "G"
                    ? `<img src="${url}" />`
                    : fileType === "M"
                        ? `<audio controls class="input--chat__block--messages__message--audio__chat--block" src="${url}">${fileName}</audio>`
                        : fileType === "V"
                            ? '<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="' +
                            url +
                            '"/></video>'
                            : `<div class="input--chat__block--messages__message--file__chat--block"><div class="input--chat__block--messages__message--file__chat"><img src="${imgGet}"/><a href="${url}">${fileName}</a></div></div>`;

            const fileId = generateFileId(url, fileType);
            // + url.split("=").pop();// generateFileId(fileType);

            const newFileMap: { type: string; id: string; url: string } = {
                type: fileType,
                id: "{" + fileId + "}",
                url: dataSave,
            };

            setFileMap((prevFileMap) => ({
                data: [...prevFileMap.data, newFileMap],
            }));

            setEditValue((prevEditValue) => prevEditValue + ` ${dataSave}`);

            setValue((oldValue) => oldValue + ` {${fileId}}`);
            showToast(`Файл ${"{" + fileId + "}"} успешно загружен!`);
        } catch (error) {
            onError("Ошибка загрузки изображения");
        }
    };

    const onChange = (newValue: string) => {
        const data = convertMessage(newValue, fileMap);
        if (data.arrayChatFiles) {
            setFileMap(data.arrayChatFiles);
        }
        setEditValue(data.disassembledText)

        setValue(newValue);
    };

    const closeEditMessage = () => {
        setValue("");
        setEditValue("");
        setEdit(false);
        setFileMap({data: []});
        setIdEditMessage(-1);
    };

    const saveMessage = () => {
        if (createTaskChatMessage) {
            if (editValue.length <= 0) {
                console.log(editValue.length);
                showToast("Невозможно отправить пустое сообщение!");
                return
            }

            setShowLoader(true);

            (async () => {
                try {
                    await store.sendMessage(editValue, chatId); //Отправлять id чата
                    setValue("");
                    setEditValue("");
                    setEdit(false);
                    setFileMap({data: []});
                    await loadChatMessages();
                    setShowLoader(false);
                } catch (e) {
                    showToast(`Произошла ошибка! ${e}`)
                    setShowLoader(false);
                }
            })();
        } else {
            showToast("У вас нет прав для отправки сообщений");
        }
    };

    function formatDate(message: string) {
        const date = new Date(message);
        return format(date, "MMM dd, yyyy");
    }

    function editMessage(message: IMessage, htmlElement: HTMLElement) {
        if (editTaskChatMessage) {
            setEdit(true);
            setIdEditMessage(message.id);
            setEditValue(message.text);
            let data = formatEditMessage(message.text, fileMap);
            if (data.arrayChatFiles) {
                setFileMap(data.arrayChatFiles);
            }
            setValue(data.disassembledText);
            setEditedMessageDiv(htmlElement);
        } else {
            showToast("У вас нет прав на редактирование комментариев");
        }
    }

    useEffect(() => {
        if (edit) scrollToBottom();
    }, [edit, idEditMessage]);

    const scrollToBottom = () => {
        if (scrollComponentDiv) {
            scrollComponentDiv.scrollTo({
                top: scrollComponentDiv.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    const scrollToMessage = () => {
        if (editedMessageDiv) {
            editedMessageDiv.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    };

    const newOptions = useMemo(() => {
        return {
            spellChecker: false,
            hideIcons: [
                "link",
                "image",
                "strikethrough",
                "table",
                "heading-bigger",
                "heading-smaller",
                "heading-1",
                "heading-2",
                "heading-3",
                "clean-block",
                "preview",
                "side-by-side",
                "fullscreen",
                "guide",

            ],
            showIcons: [
                "code",
                "bold",
                "italic",
                "quote",
                "unordered-list",
                "ordered-list",
                "code",
                "redo",
                "undo",
                "heading",
                "horizontal-rule",
            ],
            uploadImage: true,
            imageUploadFunction: imageUpload,
            placeholder: "Введите текст...",
        } as Options;
    }, []);

    return (
        <div className="input--chat__block">
            {showLoader && (
                <Loader/>
            )}
            <div className="input--chat__block--messages">
                {viewTaskChatMessage &&
                    messageGroups.map((messageGroup, index) => (
                        <div>
                            <div
                                key={index + "date"}
                                className="input--chat__block--messages__date"
                            >
                                <div className="input--chat__block--messages__date--line"></div>
                                <div className="input--chat__block--messages__date--num">
                                    {getCurrentDate(messageGroup?.date)}
                                </div>
                                <div className="input--chat__block--messages__date--line"></div>
                            </div>
                            {messageGroup?.messages?.map((message) => (
                                <div
                                    className={
                                        store.user.id === message.createdBy.id
                                            ? "input--chat__block--messages__message--block__right"
                                            : "input--chat__block--messages__message--block__left"
                                    }
                                >
                                    <div
                                        className={
                                            store.user.id ===
                                            message.createdBy.id
                                                ? "input--chat__block--messages__message--block__flex--right"
                                                : "input--chat__block--messages__message--block__flex--left"
                                        }
                                        style={
                                            edit && message.id == idEditMessage
                                                ? {
                                                    backgroundColor:
                                                        "lightblue",
                                                }
                                                : {}
                                        }
                                    >
                                        <UserPopupWrapper
                                            userId={message.createdBy.id}
                                        >
                                            <div style={{cursor: "pointer"}}>
                                                <Avatar
                                                    url={getFileUrlFromId(
                                                        message.createdBy
                                                            .photoId
                                                    )}
                                                    size="lg"
                                                />
                                            </div>
                                        </UserPopupWrapper>
                                        <div
                                            key={message.id}
                                            className="input--chat__block--messages__message"
                                        >
                                            <div className="input--chat__block--messages__message--all">
                                                <UserPopupWrapper
                                                    userId={
                                                        message.createdBy.id
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <div className="input--chat__block--messages__message--author">
                                                            {fullNameFormat(
                                                                message.createdBy,
                                                                "s n"
                                                            )}
                                                        </div>
                                                    </div>
                                                </UserPopupWrapper>
                                                <div className="input--chat__block--messages__message--role">
                                                    {message.role}
                                                </div>
                                                <div className="input--chat__block--messages__message--date">
                                                    {formatDate(
                                                        message.createdAt
                                                    )}
                                                </div>
                                                {message.updatedAt ==
                                                message.createdAt ? (
                                                    ""
                                                ) : (
                                                    <a className="input--chat__block--messages__message--update">
                                                        Изменено
                                                    </a>
                                                )}
                                            </div>
                                            <div className="input--chat__block--messages__message--text">
                                                <div dangerouslySetInnerHTML={{__html: message.text}}/>
                                            </div>
                                            {store.user.id ===
                                            message.createdBy.id ? (
                                                <div className="input--chat__block--messages__message--buttons">
                                                    <button
                                                        onClick={(e) =>
                                                            editTaskChatMessage
                                                                ? editMessage(
                                                                    message,
                                                                    e.currentTarget
                                                                )
                                                                : showToast(
                                                                    "У вас нет прав на редактирование!"
                                                                )
                                                        }
                                                    >
                                                        Редактировать
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            deleteTaskChatMessage
                                                                ? deleteMessage(
                                                                    message.id
                                                                )
                                                                : showToast(
                                                                    "У вас нет прав на удаление!"
                                                                )
                                                        }
                                                    >
                                                        Удалить
                                                    </button>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
            {value ? (
                edit ? (
                    <div>
                        <div
                            className={
                                "input--chat__block--messages__message--block__right"
                            }
                        >
                            <div
                                className={
                                    "input--chat__block--messages__message--block__flex--right"
                                }
                                style={{backgroundColor: "lightblue"}}
                            >
                                <div className="input--chat__block--messages__message">
                                    <div className="input--chat__block--messages__message--text">
                                        <div dangerouslySetInnerHTML={{__html: editValue}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        {isToday() ? (
                            <div className="input--chat__block--messages__date">
                                <div className="input--chat__block--messages__date--line"></div>
                                <div className="input--chat__block--messages__date--num">
                                    Сегодня
                                </div>
                                <div className="input--chat__block--messages__date--line"></div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="input--chat__block--messages__message--block__right">
                            <div className="input--chat__block--messages__message--block__flex--right">
                                <Avatar
                                    url={getFileUrlFromId(store.user.photoId)}
                                    size="lg"
                                />
                                <div className="input--chat__block--messages__message">
                                    <div className="input--chat__block--messages__message--all">
                                        <div className="input--chat__block--messages__message--author">
                                            {store.user.surname +
                                                " " +
                                                store.user.name}
                                        </div>
                                        <div className="input--chat__block--messages__message--date">
                                            {formatDate(String(new Date()))}
                                        </div>
                                    </div>
                                    <div className="input--chat__block--messages__message--text">
                                        {/*<MarkdownPreview content={editValue}/>*/}
                                        <div dangerouslySetInnerHTML={{__html: editValue}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : null}
            <div className="input--input__block">
                <div className="input--input__block--buttons">
                    <SimpleMDEditor
                        id="editor"
                        value={value}
                        onChange={onChange}
                        options={newOptions}
                    />
                    <div className="input--input__block--buttons__send">
                        <div className="input--input__block--buttons__send--div">
                            {edit ? (
                                <div>
                                    <button
                                        className="input--input__block--buttons__send--div__cansel"
                                        onClick={closeEditMessage}
                                    >
                                        Отмена
                                    </button>
                                    <button
                                        className="input--input__block--buttons__send--div__approve"
                                        onClick={saveEditMessage}
                                    >
                                        Сохранить
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className={createTaskChatMessage ? "input--input__block--buttons__send--div__approve"
                                        : "input--input__block--buttons__send--div__approve-block"}
                                    onClick={saveMessage}
                                >
                                    Отправить
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputChat;
