import $api from "..";
import {AxiosResponse} from "axios";
import { IFavouriteTasks, IUserMonitorGetTasksRequest, IUserMonitorTaskFullInfo } from "../models/IUserMonitorTaskFullInfo";
import { ITaskMonitorFilters } from "../models/TaskModels";

export default class UserMonitorService {
    
    static async getFavouriteTasks(): Promise<AxiosResponse<IFavouriteTasks>> {
        return $api.get<IFavouriteTasks>('/Monitor/favourite');
    }

    static async setFavouriteTasks(body: any): Promise<AxiosResponse<IFavouriteTasks>> {
        return $api.post<IFavouriteTasks>(`/Monitor/favourite`, body);
    }

    static async getTasks(body: IUserMonitorGetTasksRequest): Promise<AxiosResponse<IUserMonitorTaskFullInfo>> {
        return $api.post<IUserMonitorTaskFullInfo>(`/Monitor/getTasks`, body);
    }

    static async getFilters(): Promise<AxiosResponse<ITaskMonitorFilters>> {
        return $api.get('/Monitor/getFilters');
    }

}