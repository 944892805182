import {Dispatch, FC, SetStateAction, useContext} from "react";
import { ISortOption } from "../../../pages/UserMonitor/UserMonitor";
import SortDirectionArrow from "../../../assets/sortDirectionArrow.svg";
import { IMonitorTaskDisplay } from "../../../models/IUserMonitorTaskFullInfo";
import { Link } from "react-router-dom";
import TaskColumn from "../../Shared/TaskColumn";
import "../../../pages/UserMonitor/UserMonitor.css";
import starIdle from "../../../assets/ButtonIcons/favourite-icon-idle.svg";
import starActive from "../../../assets/ButtonIcons/favourite-icon-active.svg";
import "./TasksTable.css";
import {
    taskPriorityDisplay,
    taskStatusDisplay,
    taskTypeDisplay,
    toDisplayDate,
} from "../../Project/Tasks/Tasks";
import {AppContext} from "../../../App";
import { Title } from 'react-head';

interface ITasksTableProps {
    dataSort: ISortOption[];
    onSortChange: (code: string) => void;
    addSort: (code: string) => void;
    tasks: IMonitorTaskDisplay[];
    selectedTaskId: number | undefined;
    setSelectedTaskId: Dispatch<SetStateAction<number | undefined>>;
    favouriteTaskIds: number[];
    updateFavourite: (newFavourite: { favourited: number[] }) => void;
    onScroll: (e: any) => void;
}

const TasksTable: FC<ITasksTableProps> = ({
    dataSort,
    onSortChange,
    addSort,
    tasks,
    selectedTaskId,
    setSelectedTaskId,
    favouriteTaskIds,
    updateFavourite,
    onScroll
}) => {
    async function onFavouriteClick(taskId: number) {
        if (favouriteTaskIds.some((xx) => xx === taskId))
            updateFavourite({
                favourited: favouriteTaskIds.filter((xx) => xx !== taskId),
            });
        else
            updateFavourite({
                favourited: [...favouriteTaskIds, taskId],
            });
    }

    const { showToast } = useContext(AppContext);


    //Это отрисовочка цветной фигнюшки для колонки и подколонки.
    const taskColumnDisplay = (task: any) => {
        return (
            <div className="column-display-container">
                <div>
                    <TaskColumn
                        name={task.columns.main.name}
                        color={task.columns.main.color}
                    />
                </div>
                {task.columns.proxy ? (
                    <TaskColumn
                        name={task.columns.proxy.name}
                        color={task.columns.proxy.color}
                    />
                ) : null}
            </div>
        );
    };

    return (
        <div className="user-monitor-widget-rows-display-part card-shadow">
            <div
                onScroll={onScroll}
                className="user-monitor-widget-rows-display-part-table-container"
            >
                <table className="monitor-table-guards">
                    <thead className="custom_table_thead">
                        <tr>
                            <th style={{ width: "3%" }}></th>
                            {dataSort &&
                                dataSort.map((i) => (
                                    <th
                                        key={i.code}
                                        onClick={() => {
                                            if (i.code !== "columnTask")
                                                i.sort
                                                    ? onSortChange(i.code)
                                                    : addSort(i.code);
                                        }}
                                        className={`a_header_name_16 ${
                                            i.sort ? "no-highlight" : ""
                                        } sort-direction-changing-header ${
                                            i.code
                                        } ${i.sort === "asc" ? "" : "desc"}`}
                                    >
                                        <div>
                                            {i.lable}
                                            {i.sort ? (
                                                <img
                                                    src={SortDirectionArrow}
                                                    alt=""
                                                />
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tasks?.map((task) => (
                            <tr
                                key={task.id}
                                className={`${
                                    selectedTaskId === task.id ? "selected" : ""
                                }`}
                                onClick={(e) => {
                                    selectedTaskId === task.id
                                        ? setSelectedTaskId(undefined)
                                        : setSelectedTaskId(task.id);
                                }}
                            >
                                <td className="first">
                                    <img
                                        src={
                                            favouriteTaskIds.some(
                                                (xx) => xx == task.id
                                            )
                                                ? starActive
                                                : starIdle
                                        }
                                        className="star-button-img"
                                        alt="Избранное"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onFavouriteClick(task.id);
                                        }}
                                    />
                                </td>
                                <td>
                                    <div className="user-monitor-widget-flexy-task-name-td user-monitor-task" >
                                        {
                                            task.isGranted ?
                                            <Link
                                            className="monitor-table-link-task-name"
                                            to={`/task/${task.id}`}
                                            style={{
                                                color: "inherit",
                                                textDecoration: "none",
                                            }}
                                        >
                                            {task.name}
                                            </Link> :
                                            <div className="monitor-table-link-task-name" onClick={()=>showToast('У вас нет прав на задачу')}>
                                              {task.name}
                                            </div>
                                        }
                                    </div>
                                </td>
                                <td className="user-monitor-widget-base-td">
                                    <div className="user-monitor-widget-flexy-project-td" title={`${task.project.name}`}>
                                        {task.project.name}
                                    </div>
                                </td>
                                <td>{taskColumnDisplay(task)}</td>
                                <td className="user-monitor-widget-base-td">{taskStatusDisplay(task)}</td>
                                <td className="user-monitor-widget-base-td">{taskPriorityDisplay(task)}</td>
                                <td className="user-monitor-widget-base-td">{taskTypeDisplay(task)}</td>
                                <td className="last">
                                    {task.startDate ? toDisplayDate(task.startDate) : ""}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TasksTable;
