import { FC, useContext, useEffect, useState } from "react";
import "./PersonalSettings.css";
import CrossImg from "../../../assets/cross_grey.svg";
import { InputText } from "primereact/inputtext";
import { IEditUserInfo, IUser } from "../../../models/IUser";
import { Context } from "../../..";
import { AppContext } from "../../../App";
import Avatar from "../../Shared/Avatar/Avatar";
import ImageSelector from "../../Shared/ImageSelector/ImageSelector";
import { getFileUrlFromId } from "../../../helpers/getFileUrlFromId";
import { InputSwitch } from "primereact/inputswitch";
import {
    IEditPersonalSettings,
    IEditPersonalSettingsRequest,
    PersonalSettingsOptions,
} from "../../../models/PersonalSettings";
import {
    NotificationSettingsMap,
    ProjectEmailNotificationSettingsMap,
    TaskEmailNotificationSettingsMap,
} from "./NotificationSettingsMap";
import Loader from "../../Shared/Loader/Loader";

interface IPhoto {
    id: number | undefined;
    name: string;
}

interface IPersonalSettingsProps {
    onClose: () => void;
}

const PersonalSettings: FC<IPersonalSettingsProps> = ({ onClose }) => {
    const [tab, setTab] = useState<number>(0);
    const tabs: string[] = ["Персональные", "Дополнительно"];
    const { showToast } = useContext(AppContext);
    const { store } = useContext(Context);

    // Персональные настройки
    const [firstName, setFirstName] = useState<string>();
    const [surname, setSurname] = useState<string>();
    const [middleName, setMiddleName] = useState<string>();
    const [city, setCity] = useState<string>();
    const [photo, setPhoto] = useState<IPhoto>();

    const [nameError, setNameError] = useState<string>();
    const [surnameError, setSurnameError] = useState<string>();
    const [showLoader, setShowLoader] = useState<boolean>(false)

    // Дополнительные настройки

    const [showProxyBoards, setShowProxyBoards] =
        useState<IEditPersonalSettings>({
            id: undefined,
            paramName: PersonalSettingsOptions.showProxyBoardsLeftMenu,
            value: true,
        });

    const [notificationSettings, setNotificationSettings] = useState<
        IEditPersonalSettings[]
    >(
        Array.from(NotificationSettingsMap).map((n) => {
            return {
                id: undefined,
                paramName: n[0],
                value: true,
            };
        })
    );

    useEffect(() => {
        (async () => {
            mapUser(store.user);
            await store.getPersonalSettings();
        })();
    }, []);

    useEffect(() => {
        const showProxy = store.personalSettings.find(
            (s) =>
                s.paramName === PersonalSettingsOptions.showProxyBoardsLeftMenu
        );
        if (showProxy) setShowProxyBoards(showProxy);
        setNotificationSettings(
            notificationSettings.map((a) => {
                const setting = store.personalSettings.find(
                    (s) => s.paramName === a.paramName
                );
                if (setting) return setting;
                else return a;
            })
        );
    }, [store.personalSettings]);

    function saveSettings() {
        setShowLoader(true);
        const nameErr = validateName();
        const surnameErr = validateSurname();

        console.log("saveSettings", firstName, surname, middleName)

        if (nameErr || surnameErr){
            setShowLoader(false)
            return
        };
            // Делать проверку что именно изменено
            const editUserReq: IEditUserInfo = {
                name: firstName,
                surname: surname,
                middlename: middleName ?? null,
                city: city ?? null,
                photoId: photo ? photo.id : null,
            };
            const editSettingsReq: IEditPersonalSettingsRequest = {
                settings: [showProxyBoards, ...notificationSettings],
            };

        try {
            (async () => {
                await store.editPersonalSettings(editSettingsReq);
                const user = await store.editUserInfo(editUserReq);
                console.log("user", user)
                if (user) {
                    showToast("Сохранено");
                }
                mapUser(user);
                store.setUser(user);
                setShowLoader(false);
            })();
        } catch (err: any) {
            console.log(err);
        }
    };

    useEffect(() => {
        console.log(store.user)
        setSurname(store.user.surname);
        setFirstName(store.user.name);
        setMiddleName(store.user.middlename);
        setCity(store.user.city)
    }, [store.user]);

    const mapUser = (user: IUser) => {
        setFirstName(user.name);
        setSurname(user.surname);
        setMiddleName(user.middlename);
        setCity(user.city);
        setPhoto({
            id: user.photoId,
            name: "",
        });
    };

    const validateName = (): boolean => {
        let nameErr = undefined;

        if (!firstName || firstName.trim().length === 0) {
            nameErr = "Заполните имя";
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    };

    const validateSurname = (): boolean => {
        let surnameErr = undefined;

        if (!surname || surname.trim().length === 0) {
            surnameErr = "Заполните фамилию";
        }

        setSurnameError(surnameErr);
        return surnameErr !== undefined;
    };

    const uploadImage = async (image: File) => {
        try {
            const newFile = await store.addFile(image);
            setPhoto({
                id: newFile.id,
                name: newFile.name + "." + newFile.type,
            });
        } catch (err: any) {}
    };

    const setShowProxy = (show: boolean) => {
        const copy = JSON.parse(JSON.stringify(showProxyBoards));
        copy.value = show;
        setShowProxyBoards(copy);
    };

    const changeNotificationSettings = (paramName: string) => {
        const notificationSettingsCopy = JSON.parse(
            JSON.stringify(notificationSettings)
        );
        const setting = notificationSettingsCopy.find(
            (n: IEditPersonalSettings) => n.paramName === paramName
        );
        if (!setting) return;
        setting.value = !setting.value;
        setNotificationSettings(notificationSettingsCopy);
    };

    const PersonalTab = () => {
        return (
            <div className="settings-body-container">
                {showLoader && (
                    <Loader/>
                )}
                <div className="personal-settings-avatar-container">
                    <Avatar url={getFileUrlFromId(photo?.id)} size="xxl" />
                    <div className="personal-settings-image-selector">
                        <ImageSelector
                            onImageCropped={uploadImage}
                            fileName={`${surname}_avatar.png`}
                        />
                    </div>
                </div>
                <div className="personal-settings-input-container">
                    <label className="input_label" htmlFor="input_surname">
                        Фамилия*
                    </label>
                    <InputText
                        id="input_surname"
                        value={surname}
                        placeholder="Фамилия"
                        onBlur={validateSurname}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSurname(e.target.value)
                        }
                    />
                    <p className="error_message">{surnameError ?? ""}</p>
                </div>
                <div className="personal-settings-input-container">
                    <label className="input_label" htmlFor="input_name">
                        Имя*
                    </label>
                    <InputText
                        id="input_name"
                        value={firstName}
                        placeholder="Имя"
                        onBlur={validateName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFirstName(e.target.value)
                        }
                    />
                    <p className="error_message">{nameError ?? ""}</p>
                </div>
                <div className="personal-settings-input-container">
                    <label className="input_label" htmlFor="input_middlename">
                        Отчество
                    </label>
                    <InputText
                        id="input_middlename"
                        value={middleName}
                        placeholder="Отчество"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setMiddleName(e.target.value)
                        }
                    />
                </div>
                <div className="personal-settings-input-container">
                    <label className="input_label" htmlFor="input_city">
                        Город
                    </label>
                    <InputText
                        id="input_city"
                        value={city}
                        placeholder="Город"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCity(e.target.value)
                        }
                    />
                </div>
            </div>
        );
    };

    const NotificationPanel = () => {
        return (
            <div>
                <h1 className="personal-settings-divider-text">
                    Уведомления на почту о задачах
                </h1>
                {notificationSettings.map((a) => {
                    const settingLabel = TaskEmailNotificationSettingsMap.get(
                        Object.values(PersonalSettingsOptions).find(
                            (value) => value === a.paramName
                        ) as PersonalSettingsOptions
                    );
                    return settingLabel ? (
                        <div className="additional-settings-switch-container">
                            <label className="input_label">
                                {settingLabel}
                            </label>
                            <InputSwitch
                                className={
                                    a.value ? "show-completed-input-switch" : ""
                                }
                                checked={a.value}
                                onChange={() =>
                                    changeNotificationSettings(a.paramName)
                                }
                            />
                        </div>
                    ) : null;
                })}
                <h1 className="personal-settings-divider-text">
                    Уведомлени на почту о проектах
                </h1>
                {notificationSettings.map((a) => {
                    const settingLabel =
                        ProjectEmailNotificationSettingsMap.get(
                            Object.values(PersonalSettingsOptions).find(
                                (value) => value === a.paramName
                            ) as PersonalSettingsOptions
                        );
                    return settingLabel ? (
                        <div className="additional-settings-switch-container">
                            <label className="input_label">
                                {settingLabel}
                            </label>
                            <InputSwitch
                                className={
                                    a.value ? "show-completed-input-switch" : ""
                                }
                                checked={a.value}
                                onChange={() =>
                                    changeNotificationSettings(a.paramName)
                                }
                            />
                        </div>
                    ) : null;
                })}
            </div>
        );
    };

    const AdditionalTab = () => {
        return (
            <div className="settings-body-container">
                <div className="additional-settings-switch-container">
                    <label className="input_label">
                        Отображать прокси-доски в меню "Проекты"
                    </label>
                    <InputSwitch
                        className={
                            showProxyBoards.value
                                ? "show-completed-input-switch"
                                : ""
                        }
                        checked={showProxyBoards.value}
                        onChange={() => setShowProxy(!showProxyBoards.value)}
                    />
                </div>
                {NotificationPanel()}
            </div>
        );
    };

    return (
        <div className="personal-settings-container-container">
            <div className="personal-settings-container">
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <h1 className="header-text">Настройки</h1>
                    <button
                        className="button-base personal-settings-close-button"
                        onClick={onClose}
                    >
                        <img src={CrossImg}></img>
                    </button>
                </div>
                <div className="system__settings--header">
                    <div
                        className="system__settings--header__tabs"
                        style={{ width: "100%" }}
                    >
                        {tabs.map((tabName, i) => (
                            <button
                                className={`system__settings--header__button ${
                                    tab === i
                                        ? "system__settings--header__button__selected"
                                        : ""
                                }`}
                                onClick={() => setTab(i)}
                            >
                                {tabName}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="system__settings--body">
                    {tab === 0 ? (
                        PersonalTab()
                    ) : tab === 1 ? (
                        AdditionalTab()
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="modal-window-btns">
                    <button
                        className="p_white p_14 button_reset_style save_btn"
                        onClick={saveSettings}
                    >
                        Сохранить
                    </button>
                    <button
                        className="modal-window-margin-btn confirm_btn p_blue p_14 button_reset_style"
                        onClick={onClose}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PersonalSettings;
