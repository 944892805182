import {Link} from "react-router-dom";
import "./NavigationPanel.css";
import {ISideBarProject} from "../../../models/LeftMenuModels";
import {FC, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import InfoPanel from "../InfoPanel/InfoPanel";
import ArrowButton from "../../buttons/ArrowButton/ArrowButton";
import {Context} from "../../..";
import BoardComponent from "./BoardComponent";
import SubprojectComponent from "./SubprojectComponent";
import {IHasPermission} from "../../../models/IChekRole";
import {PersonalSettingsOptions} from "../../../models/PersonalSettings";

export interface ISideBarItem {
    label: string;
    url: string;
    isHighlighted: boolean;
}

export type HighlightOptions = "projects" | "monitor" | "archive" | "trash";

export interface INavBarInfo {
    projectId?: number;
    subprojectId?: number;
    boardId?: number;
    openProjectIds?: number[];
    openBoardIds?: number[];
}

interface NavigationPanelProps {
    userAccess: IHasPermission[]
}


const NavigationPanel: FC<NavigationPanelProps> = ({userAccess}) => {
    const {sideBarProjects, navPanelHighlight, navBarInfo, toggleProject} =
        useContext(AppContext);
    const [projectListOpen, setProjectListOpen] = useState<boolean>(false);
    const {store} = useContext(Context);
    const [showProxyBoards, setShowProxyBoards] = useState<boolean>(false);

    //ролевая модель
    const [viewMonitor, setViewMonitor] = useState(false);
    const [viewAll, setViewAll] = useState(false);
    const [viewProjects, setViewProjects] = useState(false);
    const [viewArchive, setViewArchive] = useState(false);



    useEffect(() => {
        if (navPanelHighlight === "projects") setProjectListOpen(true);
        if (
            (navBarInfo?.projectId ||
            navBarInfo?.subprojectId ||
            navBarInfo?.boardId) && navPanelHighlight !== "archive"
        )
            setProjectListOpen(true);
    }, [navPanelHighlight, navBarInfo]);

    useEffect(() => {
        const showProxy = store.personalSettings.find(
            (s) => s.paramName === PersonalSettingsOptions.showProxyBoardsLeftMenu
        );
        if (showProxy)
            setShowProxyBoards(showProxy.value as boolean);
        else
            setShowProxyBoards(true);
    }, [store.personalSettings])

    useEffect(() => {
        userAccess.forEach((xx) => {

            if (xx.functionCode == "UserMonitorAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewMonitor(true);
                    }
                })
            }

            if (xx.functionCode == "SystemProjectAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewProjects(true);
                    }
                })
            }

            if (xx.functionCode == "ArchiveAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewArchive(true);
                    }
                })
            }
            // if (store.user.email == "admin@admin.adm") {
            //     setViewProjects(true);
            //     setViewMonitor(true);
            //     setViewAll(true);
            // }
        })

    }, [userAccess]);
    return (
        <div className="nav-panel">
            {viewProjects ?
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "projects"
                            ? "nav-panel_highlighted-row"
                            : ""
                    } ${
                        navBarInfo.projectId
                            ? "nav-panel_light-highlighted-row"
                            : ""
                    }`}
                    to={"/projects"}
                >
                    <div className="nav-panel_projects-container">
                        <div className="nav-panel_label-container">
                            <div className="image-placeholder project"/>
                            <div className={`nav-panel_label`}>Проекты</div>
                        </div>
                        <div className="nav-panel_project-icons">
                            <InfoPanel
                                count={sideBarProjects?.length}
                                idKey="projectCount"
                            />
                            <ArrowButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    setProjectListOpen(!projectListOpen);
                                }}
                                open={projectListOpen}
                            />
                        </div>
                    </div>
                </Link> : null
            }
            {viewProjects || viewAll ?
            <div className="nav-panel_projects-list">
                {projectListOpen
                    ? (sideBarProjects as ISideBarProject[]).map((project) => (
                        <div key={project.id} className={`nav-panel_project-container ${navBarInfo?.openProjectIds?.includes(
                            project.id
                        ) ? "open" : ""}`}>
                            <Link
                                to={"/project/" + project.id}
                                className={`nav-panel_project ${
                                    navBarInfo?.openProjectIds?.includes(
                                        project.id
                                    )
                                        ? "open"
                                        : ""
                                } ${
                                    navBarInfo?.projectId === project.id
                                        ? "nav-panel_highlighted-row"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (navBarInfo?.projectId === project.id)
                                        toggleProject(project.id);
                                }}
                            >
                                <div className="nav-panel_label">
                                    {project.name}
                                </div>
                                <ArrowButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleProject(project.id);
                                    }}
                                    open={navBarInfo?.openProjectIds?.includes(
                                        project.id
                                    )}
                                    whiteArrow={navBarInfo?.projectId === project.id}
                                />
                            </Link>
                            {navBarInfo?.openProjectIds?.includes(
                                project.id
                            ) ? (
                                <div className="nav-panel_project-children">
                                    {project.subprojects?.length ? (
                                        <div className="nav-panel_divider">ПОДПРОЕКТЫ</div>
                                    ) : null}
                                    {project.subprojects?.map(
                                        (subproject) => (
                                            <SubprojectComponent
                                                key={subproject.id}
                                                subproject={subproject}
                                                showProxyBoards={showProxyBoards}
                                            />
                                        )
                                    )}
                                <div className="nav-panel_project-boards">
                                    <div className="nav-panel_divider">ДОСКИ</div>
                                        {project.boards
                                            .filter(
                                                (b) =>
                                                    showProxyBoards ||
                                                    !b.isProxyBoard
                                            )
                                            .map((board) => (
                                                <BoardComponent
                                                    key={board.id}
                                                    board={board}
                                                    showProxyBoards={showProxyBoards}
                                                />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ))
                    : null}
            </div> : null}
            {viewMonitor ?
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "monitor"
                            ? "nav-panel_highlighted-row"
                            : ""
                    }`}
                    to={"/monitor"}
                >
                    <div className="nav-panel_label-container">
                        <div className="image-placeholder monitor"/>
                        <div className="nav-panel_label">Монитор пользователя</div>
                    </div>
                </Link> : <div></div>}
            {!store.getBlockArchive() && viewArchive ?
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "archive"
                            ? "nav-panel_highlighted-row"
                            : ""
                    }`}
                    to={"/archive"}
                >
                    <div className="nav-panel_label-container">
                        <div className="image-placeholder archive"/>
                        <div className="nav-panel_label">Архив</div>
                    </div>
                </Link> : <div></div>}
            {viewAll ?
                <Link
                    className={`nav-panel_item ${
                        navPanelHighlight === "trash"
                            ? "nav-panel_highlighted-row"
                            : ""
                    }`}
                    to={"/trash"}
                >
                    <div className="nav-panel_label-container">
                        <div className="image-placeholder trash"/>
                        <div className="nav-panel_label">Корзина</div>
                    </div>
                </Link> : <div></div>}
        </div>
    );
};

export default NavigationPanel;
