import {createContext, FC, useEffect, useState} from 'react';
import InputChat from "../InputChat/InputChat";
import "./TaskChat.css"
import {IHasPermission} from "../../../models/IChekRole";

export const ChatContext = createContext<any>(null);


interface ITaskChat {
    userAccess: IHasPermission[]
}

const TaskChat: FC<ITaskChat> = ({userAccess}) => {

    useEffect(() => {

    }, [userAccess]);

    return (
        <div>
            <InputChat userAccess={userAccess}/>
        </div>
    );
};

export default TaskChat;